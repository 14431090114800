@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&family=Rubik:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&family=Noto+Serif:wght@400;700&family=Rubik:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&family=Noto+Serif:wght@400;700&family=Roboto+Mono:wght@200;300;400;500;600;700&family=Rubik:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@200;300;400;500;600;700&family=Libre+Baskerville:wght@400;700&family=Noto+Serif:wght@400;700&family=Roboto+Mono:wght@200;300;400;500;600;700&family=Rubik:wght@300;400;500;600;700&display=swap');






/*styles input css*/
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;1,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;0,700;0,900;1,300;1,800&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,600;0,700;0,900;1,300;1,800&family=Roboto:wght@300;400;500;700;900&display=swap');

body {
    font-family: 'Raleway', sans-serif !important;
    background-color: #F3F3F3 !important;
}

p {
    font-family: 'Raleway', sans-serif !important;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.register-bg {
    background: #3b3b3b !important;
}

.register-menu {
    min-width: 115px !important;
    max-width: 115px !important;
}

.login-img {
    height: 26px;
}

/*breadcrumb style start in css*/
.header-banner {
    position: relative;
    width: 100%;
    height: 180px;
    background: url('../public/images/job-registration.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.banner-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(52, 106, 187, .5);
}

.banner-content {
    width: 100%;
    height: 100%;
}

.slash-line {
    padding: 0 10px;

    color: #0c87d4;
}

/*breadcrumb style end in css*/
@media only screen and (max-width:992px) {
    .mobile-fix {
        flex-direction: column !important;
    }

    .banner-title {
        text-align: center !important;
        margin-bottom: 20px;
        font-size: 22px !important
    }

    .mobile-notification-bar li.border-end:nth-child(3) {
        border-right: none !important;
    }
}

.fa-dot-size {
    font-size: 12px;
}

/*header style top bar*/
.header-logo {
    position: relative;
    height: 80px;
    width: auto;
}

.footer-logo {
    height: 65px;
    width: auto;
}

.main-navbar {
    box-shadow: 0px 3px 6px 2px rgb(77 77 77 / 10%);
    z-index: 999;
}

.dropdown-bg {
    background: #262626 !important;
    border-radius: 0 !important;
    padding-top: 5px !important;
    padding-bottom: 8px !important;
    color: #b2b2b2 !important;
    min-width: 220px !important;
}

.dropdown-item:focus {
    background: #fff !important;
    text-decoration: underline;
}

.dropdown .dropdown-menu-desk .dropdown-item {
    color: #b2b2b2 !important;
}

.fs-13 {
    font-size: 13px !important;
    font-weight: 600 !important;
    color: #545454 !important;
}

.fs-8 {
    font-size: 8px;
}

.fs-9 {
    font-size: 9px;
}

.fs-10 {
    font-size: 10px;
}

.fs-11 {
    font-size: 11px;
}

.fs-12 {
    font-size: 12px;
}

.fs-15 {
    font-size: 15px;
    font-weight: 500;
}

.fw-15 {
    font-size: 15px;

}



.fs-16 {
    font-size: 16px;
}

.fs-17 {
    font-size: 17px;
}

.fs-18 {
    font-size: 18px;
}

.topbar-search-input:focus {
    outline: 0;
}




/*main theme button color*/
.button-default {
    background: #0c87d4 !important;
    color: #ffffff !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    border-radius: 0px !important;
    padding: 8px 15px !important;
}

.button-default:hover {
    background: #036eb1 !important;
    color: #ffffff !important;
    border-radius: 0px !important;
    padding: 8px 15px !important;
}

.button-default:active {
    background: #036EB1 !important;
    color: #ffffff !important;
    border-radius: 0px !important;
    padding: 8px 15px !important;
}

/*common class use in style css*/
#page-content-main {
    margin-top: 0;
    width: 100% !important;
    margin-bottom: 0px !important;
    background-color: #f3f3f3;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-600 {
    font-weight: 600 !important;
}


.fw-12 {
    font-size: 12px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fsize-13 {
    font-size: 13px !important;
}


/*sidebar css*/
.sidebar-widget {
    padding: 20px 30px !important;
    background: #fff !important;
}

.card-title {
    font-size: 22px !important;
    font-weight: 600 !important;
}

.card-para {
    color: #666 !important;
    line-height: 28px !important;
    font-size: 14px !important;
}

.card-subtitle {
    font-size: 15px;
    font-weight: 600;
    padding-top: 5px;

    color: #0c87d4;
}



.side-title {
    margin: 0 0 20px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #e6e6e6;
    font-size: 19px;
    font-weight: 600;
}

/* job registration and candidate profile upload img section in css*/
.profile-sec {
    position: relative;
    height: auto;
    text-align: center;
}

.upload-img-field {
    width: 100%;
    height: 300px;
    border: 0;
    background: url('../public/images/MalePlaceholder.png');
    background-size: cover !important;
    vertical-align: top !important;
}

.company-logo {
    width: 100%;
    height: 350px;
    border: 0;
    /* background: url('../public/images/company-logo.jpg'); */
    background-size: cover !important;
    vertical-align: top !important;
}

/* registar process stepper in css*/
.process-content {
    background: url('../public/images/process-bg.jpg') center bottom no-repeat;
    background-size: cover;
    padding: 70px 0px;
}

.candidate-register-bg {
    background: url('../public/images/jobseekers-bg.webp') center center no-repeat;
    background-size: cover;
    padding: 110px 0px;
}

.bg-img-height {
    background: url('../public/images/process-bg.jpg') center bottom no-repeat;
    background-size: cover;
    padding: 14% 0%;
    max-height: calc(100vh - 120px);
}

.password-bg-sec {
    background: url('../public/images/change-password-bg.jpg') center no-repeat;
    background-size: cover;
    padding: 14% 0%;
    max-height: calc(100vh - -100px);
}


.step1-heading {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 35px;
}

.form-section {
    max-width: 740px !important;
    margin: 0 auto;
    box-shadow: 0 0 2px rgb(0 0 0 / 20%);
    background: #fff;
}

.candidate-register-form-section {
    background: #ffffffe0 !important;
}

.form-institute-register {
    max-width: 1120px !important;
    margin: 0 auto;
    box-shadow: 0 0 2px rgb(0 0 0 / 20%);
    background: #fff;
}

/*stepper form style in css*/
.process-no {
    background: #fafafa;
    width: 100%;
    display: inline-block;
}

.process-no ul {
    padding: 0px;
}

.process-no li {
    float: left;
    text-align: center;
    width: 33.33%;
    padding-top: 10px;
    border-right: 1px solid #eeeeee;
    border-left: 0px;
    padding-bottom: 10px;
    font-size: 12px;
}

.process-no li p {
    margin: 0;
}

.process-no li span {
    height: 23px;
    width: 23px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    border: 1px solid #cccccc !important;
    margin-right: 10px;
}

.process-no li.current {
    background: #0c87d4;
    color: #fff;
}

.process-no li.current span {
    background: #fff;
    border: 1px solid #fff;
    color: #0c87d4;
}

/*pagination style in css*/

.pagination-btn {
    background-color: #0c87d4 !important;
    padding: 4px 15px !important;
}

.pagination-btn:hover {
    background-color: #036EB1 !important;
}

.pagination-diable {
    background-color: #ddd !important;
    padding: 4px 15px !important;
}

.pagination-diable:hover {
    background-color: #036EB1 !important;
}

.listdrop-padding {
    font-size: 14px;
    padding: 0px 14px !important;
    font-weight: 600;
}

.listdrop-padding:hover {

    background-color: #0c87d4;
}

.listdrop-padding:hover>.nav-link {
    color: #fff !important;
}

.dropdown.nav-item:hover a.nav-link {
    color: #fff !important;
}

.admin-header-dropdown.dropdown.nav-item:hover a.nav-link {
    color: #0c87d4 !important;
}

.admin-header-dropdown.dropdown.nav-item a.nav-link {
    /* color: #000 !important; */
}

.mobile-search {
    display: none;
}

/*_newlayout page main start style in css*/
.dropdown:hover .dropdown-menu-desk {
    display: block;
}

.dropdown .dropdown-menu-desk .dropdown-item {
    font-size: 13px;
    padding-top: 8px;
    padding-bottom: 7px;
}

.header-notification-bar {
    position: relative;
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
    background-color: #969696;
}

.navbar .navbar-nav .nav-link {
    color: #808080;
    padding: 15px 2px;
    padding-left: 0px !important;
}

.navbar .navbar-nav .nav-link:hover {
    color: #0c87d4;
}

.non-register-user .notification-section ul {
    position: relative;
    top: 7px;
    margin: 0;
}

.list-inline {
    padding-left: 0px;
    list-style: none;
}

.language-list {
    list-style: none !important;
    margin: 0 !important;
    background-color: #ddd !important;
    min-width: 45px !important;
    max-width: 45px !important;
    border-radius: 0 !important;
}

.language-list .dropdown-item1:hover {
    background-color: #ddd !important;
    color: #0c87d4 !important;
    margin-left: 10px;
}

.language-list .dropdown-item1 {
    text-align: center;
    background-color: #ddd !important;
    color: #545454 !important;
    margin-left: 10px;
}

.mob-dropdown1 .dropdown-menu {
    min-width: 47px;
}

.list-lang {
    padding: 1px 15px !important;
}

.navbar-toggler {
    background-color: #fff !important;
}

@media only screen and (max-width: 650px) and (min-width: 360px) {
    .mobile-menu {
        margin-left: 18px !important;
        margin-right: 18px !important;
    }
}

.copyright {
    background: #262626;
    padding: 25px 0;
}

.copyright span {
    color: #aaaaaa;
    font-size: 14px;
}

.copyright ul li a {
    color: #999;
    padding: 0 8px;
    font-size: 16px;
}

/* .notification-section {
    position: relative;
} */

.non-register-user .notification-section ul {
    position: relative;
    top: 7px;
    margin: 0;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

@media only screen and (max-width: 500px) and (min-width: 300px) {

    .mobile-notification-bar {
        padding: 15px 0px 2px 0px;
    }

    .mob-bg-white {
        background: #969696;
    }

    .mobile-nav {
        padding: 10px 0px 8px 0px;
    }

    .header-notification-bar {
        background: #cdcaca;
        color: #545454;
    }

    .header-notification-bar ul {
        color: #545454 !important;
    }
}

@media only screen and (max-width: 600px) and (min-width: 501px) {

    .mobile-notification-bar {
        padding: 15px 0px 2px 0px;
    }

    .mob-bg-white {
        background: #fff;
    }

    .mobile-nav {
        padding: 10px 0px 0px 0px;
    }

    .header-notification-bar {
        background: #cdcaca;
        color: #545454;
    }

    .header-notification-bar ul {
        color: #545454 !important;
    }

    .mob-bg-white {
        background: #969696;
    }
}

@media only screen and (max-width: 992px) and (min-width: 601px) {

    .mobile-notification-bar {
        padding: 15px 0px 2px 0px;
    }

    .mob-bg-white {
        background: #fff;
    }

    .mobile-nav {
        padding: 10px 0px 0px 0px;
    }

    .header-notification-bar {
        background: #cdcaca;
        color: #545454;
    }

    .header-notification-bar ul {
        color: #545454 !important;
    }

    .mob-bg-white {
        background: #969696;
    }
}


.dropdown-item-mob {
    font-size: 13px !important;
    padding-top: 8px;
    padding-bottom: 7px;
}

.nav-item.nav-item .mob-language {
    position: absolute !important;
    left: 10px !important;
    list-style: none;
    z-index: 999999999;
    margin: 0;
    padding: 5px 0;
    background-color: #ddd;
}

.fw-6-white {
    font-weight: 600;
    color: #fff;
}

.text-white-01 {
    color: #fff !important;
}

.custom-multilanguage-select {
    background-color: #969696;
    border: 1px solid #969696;
    padding: 0.12rem 2.02rem 0rem 0.2rem;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    background-image: url('../public/images/angle-down-topbar.png');
    background-size: auto;
    font-size: 14px;
}

.custom-multilanguage-select:focus {
    border-color: #969696;
    box-shadow: none !important;
}

/* .custom-multilanguage-select, option{
       padding: 10px !important;
} */

/*_newlayout page main end style in css*/

/*home page index style in css*/
.slider-div {
    padding: 35px 40px 50px !important;
    background: rgba(0, 0, 0, .25);
    margin-top: 300px;
    top: 0px;
}

.slider-div-h1 {
    font-size: 72px;
    color: #fff;
    margin: 0;
    text-align: center;
    font-weight: 600;
    margin-bottom: 15px;
}

.carousel-heights {
    height: 850px !important;
    object-fit: cover !important;
}

.carousel-heights.slide-4 {
    object-position: bottom !important;
}

@media only screen and (min-width: 1325px) {
    .carousel-heights.slide-3 {
        object-position: 0px -40px !important;
    }

    .carousel-heights.slide-2 {
        object-position: 0px -38px !important;
    }

    .carousel-heights.slide-1 {
        object-position: 0px -24px !important;
    }
}

.carousel-caption {
    position: relative !important;
    right: 0% !important;
    width: 90%;
    margin: 0 auto;
    left: 0% !important;
}

.slider-div2 {
    position: absolute;
    top: 50% !important;
    width: 100%;
}

@media only screen and (max-width: 2300px) and (min-width: 1400px) {
    .slider-div2 {
        position: absolute;
        top: 45% !important;
        width: 100%;
    }
}

/* @media only screen and (max-width: 2300px) and (min-width: 1400px){
    .carousel-heights {
        height: 950px;
        object-fit: cover !important;
    }
}     */

.hsb-text-1 {
    line-height: 35px;
}

.advance-form {
    position: relative;
    top: 100%;
    left: 0;
    z-index: 10;
    padding: 18px;
    width: 100%;
    height: auto;
}

.form-lable-collape {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
}

.bg-btn {
    background-color: #0c87d4;
    color: #fff;
    font-weight: 600;
}

.bg-btn:hover {
    background-color: #0c87d4;
    color: #fff;
}

.line-bar {
    background: #e6e6e6;
}

.line-bar:hover {
    background-color: #0c87d4;
    color: #fff;
}

@media only screen and (max-width: 600px) and (min-width: 360px) {
    .slider-div2 {
        top: 40%;
    }

    .carousel-heights {

        /*width: 150% !important;*/
        height: 780px !important;
    }

    .slide-1 {
        object-fit: cover;
        object-position: 55% 50%;
    }

    .slide-2 {
        object-fit: cover;
        object-position: 45% 50%;
    }

    .slide-3 {
        object-fit: cover;
        object-position: 35% 50%;
    }

    .slide-4 {
        object-fit: cover;
        object-position: 58% 50%;
    }

    .slider-div-h1 {
        font-size: 40px;
    }

    .mob-h2 {
        font-size: 18px !important;
    }

    .slider-div {
        padding: 40px 8px 20px !important;
    }

    .advance-form {
        padding: 0px 20px;
    }

    .desktop-search {
        display: none !important;
    }

    .carousel-caption {
        width: 100% !important;
    }

    .p-form-mob {
        padding: 20px 10px !important;
    }

    .bg-btn {
        padding: 12px 10px !important;
    }
}


@media only screen and (max-width: 790px) and (min-width: 601px) {
    .carousel-caption {
        margin-top: 110px !important;
    }

    .mobile-width {
        margin: 0px;
        padding: 0px;
        align-items: center !important;
    }

    .mobile-search {
        display: block !important;
    }

    .desktop-search {
        display: none !important;
    }

    .slider-div {
        padding: 40px 8px 20px !important;
    }

    .carousel-caption {
        width: 100% !important;
    }

    .navbar .navbar-nav .nav-link {
        padding: 10px 5px !important;
    }

    .carousel-heights {
        height: 950px !important;
        object-fit: cover !important;
        object-position: 50% 50%;
    }
}

@media only screen and (max-width: 770px) and (min-width: 560px) {
    .navbar .navbar-nav .nav-link {
        padding: 10px 0px !important;
    }
}

@media only screen and (max-width: 650px) and (min-width: 360px) {
    .flexcolumnsm {
        flex-direction: column !important;
    }

    .card-para-mobile {
        margin-top: 20px;
    }

    .alignment-list {
        align-items: unset !important;
        flex-direction: column !important;
    }

    .navbar .navbar-nav .nav-link {
        padding: 8px 7px !important;
    }
}

@media only screen and (max-width: 700px) and (min-width: 358px) {
    .mobflexcolumn {
        flex-direction: column !important;
    }

    .mobflexcolumn li {
        margin-bottom: 10px !important;
    }

    .mobflexcolumn li:first-child {
        margin-top: 10px !important;
    }

    .address-mob {
        margin-bottom: 10px !important;
    }
}


@media only screen and (max-width: 500px) and (min-width: 360px) {
    .process-no li {
        width: 50% !important;
    }
}

@media only screen and (max-width: 768px) and (min-width: 370px) {
    .margin-mob {
        margin: 8px 0px;
    }

    .m-left {
        margin-left: 28px;
    }
}


.top-search-bar:focus {
    border-radius: 0px;
    background: #f3f3f3;
}

.topbar-links {
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    color: #0c87d4;
}

.form-control {
    border: 1px solid #ced4da;
    border-radius: 0rem !important;
}

.form-control:focus {
    outline: 0;
    border-color: #423338;
    box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%);
}

.key-weight {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #262626 !important;
}

.btn-collapsed {
    background-color: #bbb3b3 !important;
    border: none !important;
}

.btn-collapsed:hover {
    background-color: #0c87d4 !important;
    border: none !important;
}


/*additional requirement skill css*/
.additional-requirement-btn a {
    padding: 5px 8px;
    display: block;
    color: #fff;
    background-color: #999;
    margin-bottom: 10px;
    font-size: 14px;
}

.additional-requirement-btn a:hover {
    background-color: #666;
    color: #fff;
}

.additional-requirement-btn a:focus {
    text-decoration: underline !important;
}


/*collapse row progress bar plus minus css*/
.minus {
    background-color: #fff;
    color: #000 !important;
    height: 30px;
    width: 30px;
    line-height: 25px;
    text-align: center;
    margin-right: 10px;
}

.minus:hover {
    background-color: #0c87d4;
    color: #fff !important;
    height: 30px;
    width: 30px;
    line-height: 25px;
    text-align: center;
    margin-right: 10px;
}

.progress-bar {
    background: #0c87d4 !important;
}


/*social share button css*/
.tags {
    padding: 5px 8px;
    display: block;
    color: #fff;
    background-color: #999;
    border-radius: 0;
    margin-bottom: 10px;
    font-size: 13px;
}

.tags:hover {
    color: #fff;
    background-color: #666;
}

.btn-register {
    background-color: #6c53da;
    padding: 10px 0px;
    width: 315px;
}

.facebook-color {
    background-color: #475996 !important;
}

.f-blue {
    color: #475996 !important;
}

.google-color {
    background-color: #ae252b !important;
}

.g-red {
    color: #ae252b !important;
}

/*form repeator button colors*/
.repeat-btns a {
    width: 84px;
    height: 27px;
    line-height: 16px;
    margin-bottom: 5px;
    text-align: center;
}

.edit-btn-color {
    background-color: #428bca !important;
    border-color: #357ebd !important;
}

.edit-btn-color:hover {
    background-color: #3071a9;
    border-color: #285e8e;
}

.delete-btn-color {
    background-color: #d9534f !important;
    border-color: #d43f3a !important;
}

.delete-btn-color:hover {
    background-color: #c9302c;
    border-color: #ac2925;
}

.save-btn-color {
    background-color: #5cb85c !important;
    border-color: #4cae4c !important;
}

.save-btn-color:hover {
    background-color: #449d44;
    border-color: #398439;
}

/*contact page css */
.sidebar-widget {
    padding: 20px 30px;
    background: #fff;
}

/*search page css */
.filter-list a {
    position: relative;
    display: block;
    padding: 5px 0;
    color: inherit;
    text-decoration: none;
    list-style: none;
}

.filter-list a:hover {
    color: #0c87d4;
}

.filter-list li {
    list-style: none;
}

.filter-result-widget .toggle {
    display: block;
    position: relative;
    width: 100%;
    height: 1px;
    background: #0c87d4;
    text-decoration: none;
}

.filter-result-widget .up-toggle {
    position: relative;
    left: 50%;
    width: 35px;
    display: block;
    text-align: center;
    color: #ffffff;
    background: #0c87d4;
    margin-left: -15px;
}

.filter-list-ul {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: #262626;
}

.w-selected {
    width: 200px !important;
}

.pt-80 {
    padding-top: 80px;
}

/*job registration css*/

.job-description {
    line-height: 20px;
    font-size: 13px;
    font-weight: 400;
}

.job-regitration-input {
    padding: 0;
    margin: 0;
    border: 0;
    font-weight: normal;
    color: #333;
    border: 0px solid #ccc !important;
    font-size: 14px;
}

.job-regitration-input:focus {
    border: none !important;
}

.input-text {
    font-size: 13px;
}

.cancel-btn {
    background: #4d4d4d;
}

.cancel-btn:hover {
    background: #121111 !important;
}

.label-text {
    font-size: 14px;
    font-weight: 600;
}

.btn-preview {
    background: #2cb02c !important;
    padding: 8px 16px !important;
}

.btn-preview-register {
    padding: 10px 16px;
    background: #2cb02c;
}

.btn-preview-register:hover {
    padding: 10px 16px;
    background: #66cc66;
}

.btn-preview:hover {
    background: #66cc66 !important;
    padding: 8px 16px !important;
}

.btn-interest {
    background: #2cb02c !important;
    padding: 8px 32px !important;
}

.btn-interest:hover {
    background: #66cc66 !important;
    padding: 8px 32px !important;
}

.w-selected {
    width: 130px;
}

.job-page-map {
    height: 300px;
    padding: 5px;
    margin-bottom: 35px;
}

/*candidate registration css*/
.job-regitration-input {
    padding: 0;
    margin: 0;
    border: 0;
    font-weight: normal;
    color: #333;
    border: 0px solid #ccc !important;
    font-size: 14px;
}

.job-regitration-input:focus {
    border: none !important;
}

.cancel-btn {
    background: #4d4d4d !important;
}

.cancel-btn:hover {
    background: #121111 !important;
}

.skill-progress-color {
    background: #e8e8e878;
    box-shadow: inset 1px 1px 0px #ddd;
}

.w-select {
    width: 215px !important;
}

.fs-radio {
    font-size: 14px;
    font-weight: normal;
    color: #333;
}

.w-45 {
    width: 42%;
}

/*company list css page*/
.w-select-list {
    width: 215px;
}

.top-bg-company {
    background-color: #e1e0e0;
}

.topbar-search a {
    color: #666;
    padding: 6px 10px;
}

.topbar-search a:hover {
    color: #666;
    background-color: #fff;
}

.font-sab-table {
    font-size: 13px;
    color: #666;
}

.weight-list {
    font-weight: 600;
}


.hide {
    display: none;
}

.hidding:hover {
    color: #66cc66 !important;
}

.jobsNumber {
    color: #666 !important;
    position: relative !important;
    font-size: 42px !important;
    font-weight: 600 !important;
    cursor: pointer !important;
}

.jobsNumber:hover {
    color: #66cc66 !important;
}

.table-header {
    background-color: #f8f8f8;
    padding: 5px !important;
}

.card-description a {
    color: #666;
    font-size: 13px;
}

.card-description a:hover {
    text-decoration: underline !important;
}

.table-head-padding {
    color: #666;
    font-weight: 600;
    margin: 0;
    padding: 15px 25px !important;
    font-size: 17px;
}

.search-icon {
    margin-top: 10px;
}

/*registration processs 1 css*/
.padding-step1 {
    padding: 25px;
}

.mb-12 {
    margin-bottom: 12px;
}

.p-btn {
    padding: 10px 40px;
}

.btn-contine {
    padding: 10px 40px;
    background: #0c87d4;
}

.btn-contine:hover {
    background: #036EB1;
}



/*registration processs 2 css*/
.padding-step2 {
    padding: 50px;
}

.confirm-email p {
    font-size: 16px;
    line-height: 26px;
    max-width: 320px;
    margin: 0 auto;
    margin-top: 40px;
}

.mb-30 {
    margin-bottom: 30px;
}

/*registration processs 3 css*/
.padding-step3 {
    padding: 50px;
}

.confirm-email p {
    font-size: 14px;
    line-height: 26px;
    margin: 0 auto;
    margin-top: 10px;
}

.p-btn {
    padding: 10px 40px !important;
}

.margin-btn {
    margin-top: 30px;
}

.mt-30 {
    margin-top: 30px;
}

/*Edit company profile page css*/
#page-editprofile {
    background: #fff;
}

.bg-field {
    background-color: #f1f1f1;
}

.label-text {
    font-size: 14px;
    font-weight: 600;
}

.input-text {
    font-size: 13px;
    font-weight: 600;
}

.social-icon {
    padding: 8px 12px;
}

.facebook-color {
    background: #4662b2 !important;
}

.whatsapp-color {
    color: #075E54 !important;
}

.whatsapp-bgcolor {
    background: #25D366 !important;
}

.linkedin-color {
    background: #2c85b8 !important;
}

.twitter-color {
    background: #66a8de !important;
}

.youtube-color {
    background: #e22114;
}

.google-color {
    background: #dbdbdb;
}

.instagram-color {
    background: #71594e;
}


@media only screen and (max-width: 577px) and (min-width: 358px) {
    .xs-margin {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .m-top {
        margin-top: 0px !important;
    }
}

.sub-preview-btn {
    padding: 13px 55px;
}

.preview-color {
    background: #4d4d4d;
}

.preview-color:hover {
    background: #000;
}

.submit-color {
    background: #0c87d4;
}

.m-top {
    margin-top: 43px;
}

@media only screen and (max-width: 680px) and (min-width: 358px) {
    .m-top {
        margin-top: 0px !important;
    }

    .mobdepartmentcolumn {
        flex-direction: column !important;
        width: 100%;
        margin-left: 0px !important;
    }

    .mobdepartmentcolumn li {
        margin-left: 0px !important;
    }

    .w-15a {
        width: 100% !important;
    }
}

.w-15a {
    width: 15%;
}

/*job preview page css*/
.job-title {
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
}

.job-description {
    line-height: 20px;
    font-size: 13px;
    font-weight: 400;
}

.videowrap {
    position: relative;
    padding-bottom: 48%;
    padding-top: 25px;
    height: 0;
    margin-bottom: 30px;
    margin-left: 40px;
    margin-right: 40px;
}

.language-icon {
    background: #ccc;
    padding: 5px;
}

.language-icon:hover {
    background: #0c87d4;
}

@media only screen and (max-width: 700px) and (min-width: 360px) {
    .videowrap {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .detail-content-preview {
        margin-top: 170px;
    }
}

@media only screen and (max-width: 1050px) and (min-width: 700px) {
    .videowrap {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .detail-content-preview {
        padding-top: 140px;
    }
}

/*Company Applicants pepsi page in css*/
.card-subtitle {
    font-size: 15px;
    font-weight: 600;
    padding-top: 5px;
    color: #0c87d4;
}

/*style add in tabs*/
.client-side-title {
    font-size: 15px;
    color: #333;
    font-weight: 600;
}

.rating {
    font-size: 48px;
    color: #0c87d4;
    font-weight: 400 !important;
    font-style: italic !important;
}

.profile-icon a {
    color: #333;
}

.profile-icon a:hover {
    color: #0c87d4;
}

.list-title {
    padding-top: 10px;
}

/*company profile page in css*/
/*style add in tabs*/
.main-sidebar-title {
    color: #333;
    font-size: 16px;
    font-weight: 600;
}

.client-side-title {
    font-size: 15px;
    color: #333;
    font-weight: 600;
}

.language-icon {
    background: #ccc;
    padding: 5px;
}

.language-icon:hover {
    background: #0c87d4;
}

.trash-icon {
    background: #ccc;
    padding: 5px;
    margin-left: 6px;
}

.trash-icon:hover {
    background: #000;
}


.language-font {
    font-size: 15px;
    font-weight: 600;
}

.rating {
    font-size: 48px;
    color: #0c87d4;
    font-weight: 400 !important;
}

.profile-icon a {
    color: #333;
}

.profile-icon a:hover {
    color: #0c87d4;
}

.list-title {
    padding-top: 10px;
}

.color-tab.active {
    background: #0c87d4 !important;
}

/*candidate profile page css*/
.bg-btn {
    background-color: #0c87d4;
    color: #fff;
    padding: 6px 10px;
    font-size: 14px;
    font-weight: 400;
}

.bg-btn:hover {
    background-color: #0c87d4;
    color: #fff;
}

.bg-gray-btn {
    background-color: #ddd;
    padding: 6px 10px;
    font-size: 14px;
    font-weight: 400;
}

.bg-gray-btn:hover {
    background-color: #0c87d4;
}

.text-color-33 {
    color: #333333 !important;
    font-weight: 600;
    margin-bottom: 35px;
}

.text-color-66 {
    color: #666666 !important;
    font-size: 14px;
    line-height: 28px;
}

.social-icons-box {
    display: flex;
    align-items: center;
}

.icon {
    padding: 0;
    height: 30px;
    width: 30px;
    border-radius: 0;
    text-align: center;
    margin-right: 11px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon.fb-icon {
    background-color: #0c87d4;
    color: #fff;
}

.icon.mail-icon {
    background-color: #CCCCCC;
    color: #fff;
}

.icon.googleplus-icon {
    background-color: #383935;
    color: #fff;
}

.icon.twitter-icon {
    background-color: #66A8DE;
    color: #fff;
}

.icon.linkedin-icon {
    background-color: #4172AE;
    color: #fff;
}

.icon.pinterest-icon {
    background-color: #AE252B;
    color: #fff;
}

.language-print .list-inline-item {
    font-size: 15px;
    font-weight: 600 !important;
}

.list-inline-item.border-right {
    margin-right: 20px;
    padding-right: 20px;
    border-right: 1px dotted #ccc;
}

.language-print .list-inline-item a {
    text-decoration: none !important;
    color: #ccc !important;
}

.language-print .list-inline-item.active a {
    color: #0c87d4 !important;
}

.language-print .list-inline-item.print a,
.language-print .list-inline-item.star-rating a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    height: 30px;
    width: 30px;
    background: #ccc;
}

.language-print .list-inline-item.print a:hover,
.language-print .list-inline-item.star-rating a:hover {
    background: #0c87d4 !important;
}

.py-50 {
    padding-top: 50px;
    padding-bottom: 40px;
}

.py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.admin-footer-40 {
    padding-bottom: 40px;
}



/*candidate profile end*/

/*responsive design fix */
@media only screen and (max-width: 500px) and (min-width: 360px) {
    .select2 {
        width: 190px !important;
        margin-right: 12px;
    }

    .select-mob {
        width: 178px !important;
    }
}

@media only screen and (max-width: 768px) and (min-width: 358px) {
    .margin-language {
        margin-bottom: 20px;
    }

    .mt-mob {
        margin-top: 30px;
    }

    .border-no {
        border: 0px !important;
    }

    .m-mob-pag {
        margin-right: 5px;
    }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
    .margin-language {
        margin-bottom: 20px;
    }
}

.list-inline-requirement li {
    display: inline-block;
}

@media (min-width: 1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1140px !important;
    }
}

.font-input {
    font-family: 'poppins', sans-serif;
    font-size: 14px;
}


.form-control:focus {
    box-shadow: none !important;
    z-index: auto !important;
}

/* carousel slider in react-bootstrap */
.carousel-control-prev {
    display: none !important;
}

.carousel-control-prev {
    display: none !important;
}

.carousel-control-next {
    display: none !important;
}

.carousel-indicators {
    display: none !important;
}

.group-round {
    border-radius: 0px !important;
}

.gray-form {
    background-color: #dcd9d9 !important;
}

.darkblue {
    color: rgba(13, 82, 128, 0.918) !important;
    ;
    font-weight: 500;
}

/* contact us page style */
.location-icons {
    padding: 5px 10px 10px;
    background-color: #0c87d4;
    color: #fff;
}

.contact-widget {
    padding: 20px 15px;
    background-color: #fff;
}

.p-icons {
    padding: 8px 0px;
}

.mob-header-bg.hideIT992 {
    display: none !important;
}

.mobile-header .logo-section {
    display: block;
}

.mob-header-bg.hideIT992 {
    display: none !important;
}

.mobile-header .logo-section {
    display: block;
}

/* header layout page css start */
@media only screen and (max-width:992px) {
    .Dektop {
        display: none !important;
    }
}

@media only screen and (min-width:993px) {
    .Mobile {
        display: none !important;
    }
}

@media only Screen and (max-width:992px) {
    .Mobile .dropdown-menu {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        margin-bottom: 15px !important;
    }

    .mob-header-bg.hideIT992 {
        display: flex !important;
    }

    .mobile-header .logo-section,
    .welcomeAndre {
        display: none;
    }

    .mobile-header.justify-content-between {
        justify-content: center !important;
    }


    .mobile-header .logo-section,
    .welcomeAndre {
        display: none;
    }

    .mobile-header.justify-content-between {
        justify-content: center !important;
    }
}

/* header layout page css end */

.mob-header-bg {
    background-color: #969696 !important;
    display: flex !important;
    width: 100% !important;
    justify-content: space-between !important;
}

.mobile-toggle {
    padding: 0px !important;
}



@media only Screen and (max-width:767px) {
    .form-m-center {
        margin-left: 5px !important;
        margin-right: 5px !important;
    }
}

@media only Screen and (max-width:550px) {
    .step1-heading {
        font-size: 20px;
    }
}

.m-top-3 {
    margin-top: 3px;
}


.customBlackNavDropdown .dropdown-menu {
    background: #fff !important;
    color: #000 !important;
    min-width: 160px !important;
    border-radius: 0px !important;
    z-index: 999999999 !important;
    filter: drop-shadow(3px 5px 10px #ffffff73);
    padding-top: 10px;
    padding-bottom: 18px;
}

.customBlackNavDropdown .dropdown-menu .dropdown-item {
    font-size: 12px !important;
    padding-left: 12px !important;
    margin-top: 12px !important;
    color: #000 !important;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 3px;
}

.customBlackNavDropdown .dropdown-menu .dropdown-item.active,
.dropdown-item:active {
    background: none !important;
}

@media only screen and (min-width: 991px) {
    .customBlackNavDropdown .dropdown-menu .dropdown-item:hover {
        background: #fff !important;
        color: rgb(15, 49, 238) !important;
        font-size: 12px !important;
        font-weight: 400;
    }
}

.language-dropdown .dropdown-menu {
    background: #ddd !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    color: #b2b2b2 !important;
    border-radius: 0px !important;
    z-index: 999999999 !important;
    filter: drop-shadow(3px 5px 10px #ffffff73);
    transform: translate(0px, 20px) !important;
}

.language-dropdown .dropdown-menu .dropdown-item {
    font-size: 13px !important;
    padding: 1px 10px !important;
    color: #545454 !important;
}

.svg-black {
    -webkit-filter: invert(1) !important;
    filter: invert(1) !important;
}

.fw-600 {
    font-weight: 600 !important;
}

select option[selected] {
    font-weight: 600 !important;
}

@media only screen and (min-width:992px) {
    .customBlackNavDropdown .dropdown-menu[data-bs-popper] {
        top: 97% !important;
        left: -14px !important;
    }

    .notification-section,
    .w-80 {
        width: 85% !important;
        margin: auto !important;
    }

    #nav-dropdown-language {
        color: #fff !important;
    }

    #nav-dropdown-register {
        color: #fff !important;
    }

    .navbar-light.bg-light {
        background-color: #fff !important;
    }
}

@media only screen and (max-width:993px) {
    .header-logo {
        height: 70px !important;
    }

    .mob-logo {
        display: none;
    }
}

/* skill matcher page style is start css */
.skill-banner-title {
    z-index: 1;
    background: #EBEFF3;
    padding: 15px 20px;
    color: #0c87d4;
    font-size: 22px;
    line-height: 60px;
    font-weight: 500;
}

.ribbon-widget {
    background-color: #f0f1f4;
    margin-top: 1em;
    margin-left: 9px;
}

.a-link {
    color: #004481;
    text-decoration: underline;
}

.a-link:hover {
    color: #23527c;
    text-decoration: none;
}

.ribbon-widget .ribbon-title {
    left: -9px;
    top: -14px;
    color: #fff;
    line-height: 26px;
    margin-top: 10px;
    position: relative;
    white-space: normal;
    padding: 5px 15px;
    font-family: Source Sans Pro, sans-serif;
    background: #0c87d4;
    font-size: 18px;
    width: 95%;
}

.ribbon-title:before {
    display: block;
    content: "";
    font-size: 0;
    width: 9px;
    height: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    background: url('../public/images/sprites.png') -279px 0 no-repeat;
}

.ribbon-para {
    padding-left: 20px;
    padding-right: 35px;
    color: #000;
    font-weight: 500;
}

.social-icon-skill {
    padding: 5px 5px;
}

.para-skill {
    padding-top: 20px;
    padding-bottom: 30px;
    font-size: 20px;
}

.border-skill-icon {
    border-bottom: 1px solid #c6c9cc;
    padding-bottom: 10px;
}

.border-bottom-light {
    border-bottom: 1px solid #c6c9cc !important;
}

.start-skill-btn {
    background: #fff;
    font-size: 20px;
    font-family: Arial, sans-serif;
    font-weight: 700;
    color: #0c87d4;
    border: 2px solid #0c87d4;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 0;
    box-shadow: none;
    white-space: unset;
    padding: 2% 6%;
}

.skill-title {
    margin-bottom: 30px;
}

.matcher-skill-btn {
    background: #fff;
    font-size: 20px;
    font-family: Arial, sans-serif;
    font-weight: 700;
    color: #0c87d4;
    border: 2px solid #0c87d4;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 0;
    box-shadow: none;
    white-space: unset;
    padding: 2% 6%;
}

.matcher-skill-btn:hover {
    background: #0c87d4;
    border: 2px solid #0c87d4;
    color: #fff;
}

.icon-colors {
    color: #0c87d4;
}

.skill-border-btn {
    margin: 5em 0em 8em 0em;
}

.matcher-para {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.assessment-list {
    padding-left: 1.5em;
    margin-left: 0;
}

.radio-btn {
    font-size: 20px;
    margin-bottom: 5px;
}

.lh-skill {
    line-height: 16px;
}

.p-td {
    padding-top: 10px !important;

    padding-bottom: 15px !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-700 {
    font-weight: 700 !important;
    /* color: #000 !important; */
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}


.td-data {
    font-weight: 400 !important;
    color: #000 !important;
}

/* skill matcher page style is end css */


/* topbar dropdown welcome menu */
.w-25 {
    width: 25% !important;
}

.w-35 {
    width: 35% !important;
}

.welcome-dropdown .dropdown-menu {
    color: #891010 !important;
    min-width: 200px !important;
    border-radius: 6px !important;
    z-index: 999999999 !important;
    filter: drop-shadow(3px 5px 10px #ffffff73);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);
    margin-top: 20px !important;
    border: none;
}

.welcome-dropdown .dropdown-menu::before {
    content: '' !important;
    position: absolute;
    top: 0;
    right: 2%;
    margin-left: -15px;
    margin-top: -15px;
    width: 0;
    z-index: 1;
    height: 0;
    border-bottom: solid 15px #FFF;
    border-left: solid 15px transparent;
    border-right: solid 15px transparent;
}

.profile-icon-topbar.dropdown.show .dropdown-toggle::after {
    position: relative;
    top: -1px;
    content: url('../public/images/angle-up-topbar.png') !important;
    vertical-align: -3px;
    border-top: 0px;
    right: 5px;
    margin-left: 10px;
}

.profile-icon-topbar.dropdown .dropdown-toggle::after {
    position: relative;
    top: -1px;
    content: url('../public/images/angle-down-topbar.png');
    vertical-align: -3px;
    border-top: 0px;
    right: 5px;
    margin-left: 10px;
}

.wel-color {
    color: #0c87d4 !important;
    font-weight: 500 !important;
}

.p-none-country .ReactFlagsSelect-module_selectBtn__19wW7 {
    padding-bottom: 0px !important;
}

/* skill matcher results page css */

.p_header {
    padding: 15px 0.5em !important;
}

.theader {
    background-color: #c7c7c7;
}

.p-result-td {
    padding: 15px 15px !important;
}

.title-sidebar {
    background-color: #0c87d4;
    padding: 6px;
}

.img-border {
    width: 100%;
    height: auto;
    margin-top: 10px;
    border: 3px solid #aab2bc;
    margin-bottom: 25px;
    padding-top: 10px;
    background-color: #fff;
    padding-bottom: 10px;
}

.title-show {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
}

.experience-list a {
    color: #0c87d4;
    padding: 0px;
    text-decoration: underline;
    font-weight: 500;
}

.title-exper {
    font-size: 16px;
    color: #000;
    font-weight: 700;
}

.panel-head {
    background-color: #969696;
    border: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 0;
    padding-left: 12px;
}

.panel-para {
    padding: 10px 15px;
    background: #e2e2e2;
    font-size: 13px;
}


.tr-skill-result:nth-child(odd) {
    background-color: #f3f3f3;
}

.tr-skill-result:nth-child(even) {
    background-color: #fbfbfb;
}

/* css style start job assment page is sulman */

.assessment-list {
    padding-left: 1.5em;
    margin-left: 0;
}

.jobActivity.form-check-input[type=radio] {
    border-radius: 0% !important;
}

.jobActivity.form-check-input {
    width: 60px !important;
    height: 30px !important;
}

.jobActivity.form-check-input {
    content: url(../public/block.png);

    background-color: #dddddd !important;
    border-color: #c1c1c1 !important;
    border-width: 2px !important;
    border-style: solid !important;
    background-image: url("data:image/svg+xml;charset=utf-8,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDIwMDEwOTA0Ly9FTiIKICJodHRwOi8vd3d3LnczLm9yZy9UUi8yMDAxL1JFQy1TVkctMjAwMTA5MDQvRFREL3N2ZzEwLmR0ZCI+CjxzdmcgdmVyc2lvbj0iMS4wIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiB3aWR0aD0iNjAuMDAwMDAwcHQiIGhlaWdodD0iMzAuMDAwMDAwcHQiIHZpZXdCb3g9IjAgMCA2MC4wMDAwMDAgMzAuMDAwMDAwIgogcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQgbWVldCI+Cgo8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwzMC4wMDAwMDApIHNjYWxlKDAuMTAwMDAwLC0wLjEwMDAwMCkiCmZpbGw9IiMwMDAwMDAiIHN0cm9rZT0ibm9uZSI+CjwvZz4KPC9zdmc+Cg==") !important;

}

.jobActivity.form-check-input:checked {
    content: url(../public/check-mark.png);

    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    border-width: 2px !important;
    border-style: solid !important;
    background-image: url("data:image/svg+xml;charset=utf-8,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDIwMDEwOTA0Ly9FTiIKICJodHRwOi8vd3d3LnczLm9yZy9UUi8yMDAxL1JFQy1TVkctMjAwMTA5MDQvRFREL3N2ZzEwLmR0ZCI+CjxzdmcgdmVyc2lvbj0iMS4wIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiB3aWR0aD0iMTI4LjAwMDAwMHB0IiBoZWlnaHQ9IjEyOC4wMDAwMDBwdCIgdmlld0JveD0iMCAwIDEyOC4wMDAwMDAgMTI4LjAwMDAwMCIKIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIG1lZXQiPgoKPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsMTI4LjAwMDAwMCkgc2NhbGUoMC4xMDAwMDAsLTAuMTAwMDAwKSIKZmlsbD0iIzAwMDAwMCIgc3Ryb2tlPSJub25lIj4KPHBhdGggZD0iTTEgOTk4IGMxIC0yNjcgMSAtMjc5IDE0IC0yMTggNDkgMjI5IDI1NiA0MzYgNDg1IDQ4NSA2MSAxMyA0OSAxMwotMjE3IDE0IGwtMjgzIDEgMSAtMjgyeiIvPgo8cGF0aCBkPSJNNzgwIDEyNjQgYzM2IC03IDEwMyAtMzIgMTUwIC01NSAxNjIgLTgwIDI5NyAtMjU0IDMzNSAtNDI5IDEzIC02MQoxMyAtNDkgMTQgMjE4IGwxIDI4MiAtMjgyIC0xIGMtMjY4IC0xIC0yNzkgLTEgLTIxOCAtMTV6Ii8+CjxwYXRoIGQ9Ik01MDIgMTE2MCBjLTEzNyAtMzYgLTI2MiAtMTMxIC0zMzAgLTI1MSAtMzkgLTY5IC03MiAtMTkyIC03MiAtMjY5CjAgLTc3IDMzIC0yMDAgNzIgLTI2OSA0MSAtNzIgMTI3IC0xNTggMTk5IC0xOTkgNjkgLTM5IDE5MiAtNzIgMjY5IC03MiA3NyAwCjIwMCAzMyAyNjkgNzIgNzIgNDEgMTU4IDEyNyAxOTkgMTk5IDM5IDY5IDcyIDE5MiA3MiAyNjkgMCA3NyAtMzMgMjAwIC03MgoyNjkgLTQxIDcyIC0xMjcgMTU4IC0xOTkgMTk5IC03MSA0MCAtMTkzIDcyIC0yNzMgNzEgLTM0IDAgLTk0IC05IC0xMzQgLTE5egptNDE4IC0zMjAgYzExIC0xMSAyMCAtMjggMjAgLTM3IDAgLTIzIC0zNjAgLTM4MyAtMzg0IC0zODMgLTIxIDAgLTIwMyAxNzMKLTIxMiAyMDIgLTcgMjQgMjIgNTggNTAgNTggMTIgMCA0OCAtMjggODggLTY3IGw2OCAtNjcgMTU4IDE1NyBjODcgODYgMTY2CjE1NyAxNzUgMTU3IDkgMCAyNiAtOSAzNyAtMjB6Ii8+CjxwYXRoIGQ9Ik0xIDI4MyBsLTEgLTI4MyAyODMgMSBjMjY2IDEgMjc4IDEgMjE4IDE0IC0zNSA4IC0xMDMgMzMgLTE1MCA1NwotMTY1IDgxIC0yOTcgMjUwIC0zMzUgNDI4IC0xNCA2MSAtMTQgNTAgLTE1IC0yMTd6Ii8+CjxwYXRoIGQ9Ik0xMjYzIDQ5OCBjLTcgLTM1IC0zMSAtMTAxIC01NCAtMTQ4IC04MSAtMTYzIC0yNTIgLTI5NiAtNDI5IC0zMzQKLTYxIC0xNCAtNTAgLTE0IDIxOCAtMTUgbDI4MiAtMSAwIDI4MCBjMCAxNTQgLTEgMjgwIC0zIDI4MCAtMSAwIC04IC0yOCAtMTQKLTYyeiIvPgo8L2c+Cjwvc3ZnPgo=") !important;

}

.share-form {
    background-color: #bfd0df !important;
    padding: 15px !important;
    margin: 0 0 5px !important;
}

/* css style end job assment page is sulman */

/* page print style css start */
@media all {
    .page-break {
        page-break-before: always;
        page-break-after: always;
        page-break-inside: avoid;
    }
}

@media print {

    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
        margin-top: 0px !important;
    }

    .print-cross-hide {
        display: none;
    }

    .print-mt-0 {
        margin-top: 0px !important;
    }
}

@media print {
    .page-break {
        margin-top: 1rem;
        page-break-before: auto;
        page-break-after: always;
        page-break-inside: avoid;
    }
}

@page print-invoice-modal {
    size: auto;
    /* margin: 20mm 20mm 20mm 20mm; */
    margin: 2mm 4mm !important;
}

div.print-invoice-modal {
    page: print-invoice-modal;
}

@page {
    size: auto;
}

@page :first {
    margin-top: 0in !important;
    margin-bottom: 0.3in !important;
}

/* Targets all even-numbered pages */
@page :left {
    margin-top: 0.5in;
    margin-bottom: 0.2in;
}

/* Targets all odd-numbered pages */
@page :right {
    margin-top: 0.5in;
    margin-bottom: 0.2in;
}

/* page print style css end */

.pb-result {
    padding: 4px 8px;
    background-color: #E1E6EC;
}

.pb-result-active {
    padding: 4px 8px;
}

.pb-result-end {
    padding: 4px 8px;
    background-color: #0c87d4;
    border-color: #0c87d4;
}

.bg-gray {
    background-color: #969696 !important;
}

.bg-light-gray {
    background-color: #e2e2e2 !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.border-none .border-skill-icon {
    border: none !important;
}

.img-w-345px {
    width: 150px !important;
    height: auto !important;
    /* object-fit: cover !important;
    object-position: center !important; */
}

.bg-logo {
    background-color: #d4d4d4;
}

/* resume page style is start */
.new-resume {
    cursor: pointer;
    height: 362px;
    border-radius: 4px;
    border: 2px dashed #c3c4c5;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    letter-spacing: 1.25px;
    text-align: center;
    text-transform: uppercase;
    color: #0c87d4;
    display: flex;
    flex-flow: column;
    justify-self: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 16px;
    box-sizing: border-box;
    transition: all 0.2s ease 0s;
}

.new-resume:hover {
    border: 2px dashed #0c87d4;
}

.plus-icon-border {
    width: 56px;
    height: 56px;
    border: 2px dashed #0c87d4;
    border-radius: 100%;
    align-self: center;
    margin-bottom: 12px;
}

.doc-untitle {
    height: 362px;
    border-radius: 4px;
    box-shadow: rgb(181 186 189) 0px 1px 4px 0px, rgb(231 235 238) 0px 3px 8px 0px;
    overflow-x: hidden;
    transition: all 0.3s ease 0s;
    background-color: rgb(255, 255, 255);
}

.resume-card-img {
    height: 100%;
    width: 100%;
    display: grid;
    flex-direction: column;
    position: relative;
    background-size: contain;
}

.edit-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: none;
    transition: .5s ease;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 999;
}

.resume-card-img:hover .edit-overlay {
    opacity: 1;
}

.edit-btn {
    color: white;
    font-size: 20px;
    position: absolute !important;
    top: 45%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.card-untitled {
    box-sizing: border-box;
    min-height: 140px;
    max-height: 156px;
    background-color: rgb(255, 255, 255);
    padding: 16px 16px;
    /* box-shadow: rgb(31 31 31 / 25%) 0px 0px 32px 0px; */
    border-top: 2px dashed #c3c4c5;
}

.fw-16 {
    font-size: 16px !important;
}

.input-resume:focus {
    background-color: rgb(225, 241, 253) !important;
    border-bottom: 2px dashed rgb(59, 136, 194) !important;
}

.color-text {
    color: #0c87d4;
}

.fs-20 {
    font-size: 20px;
    font-weight: 600;
}

.fs-21 {
    font-size: 21px;
}

.fs-22 {
    font-size: 22px !important;
}

.fs-23 {
    font-size: 23px !important;
}

.fs-24 {
    font-size: 24px !important;
}

.dropdown.resume-dropdown .dropdown-toggle::after {
    display: none !important;
}

.dropdown.resume-dropdown .dropdown-toggle.btn.btn-primary {
    background-color: #fff0 !important;
    border-color: #fff0 !important;
    padding: 0px !important;
}

.icon-gray {
    color: #6D7275;
}

/* resume page style is end */


/* resume templates page css start */
.template-img {
    width: 230px !important;
    border-radius: 4px;
    cursor: pointer;
    object-fit: cover;
}

.template-img-resume {
    width: 150px !important;
    height: 205px !important;
    border-radius: 4px;
    cursor: pointer;
    object-fit: cover;
}

.template-container {
    transition: transform 0.2s ease-in-out 0s;
    box-shadow: rgb(82 98 109 / 4%) 0px 0px 3px 4px, rgb(0 0 0 / 8%) 0px 0px 0px 1px;
    border-radius: 4px;
    position: relative;
}

.template-container-resume {
    transition: transform 0.2s ease-in-out 0s;
    box-shadow: rgb(82 98 109 / 4%) 0px 0px 3px 4px, rgb(0 0 0 / 8%) 0px 0px 0px 1px;
    border-radius: 4px;
    position: relative;
}


.template-container:hover {
    z-index: 2;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.5);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.5);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.5);
    transition: all 200ms ease-in;
    transform: scale(1.3);
}

.slick-next:before,
.slick-prev:before {
    color: #0c87d4;
}

.template-overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: none;
    transition: .5s ease;
    background-color: rgba(255, 255, 255, 0.6);
}

.template-btn {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.overlay-temp {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.img-cont:hover .overlay-temp {
    background: rgb(182 207 234 / 18%);
}

.button-temp {
    text-align: center;
    transform: scale(0.6);
    opacity: 0;
    position: absolute;
    bottom: 5px;
}

.title-resume {
    font-size: 35px;
    font-weight: bolder;
    color: #214461;
}

.img-cont:hover .button-temp {
    opacity: 1;
}

.btn-template {
    padding: 9px 30px !important;
    font-size: 14px !important;
    background-color: #0c87d4 !important;
}

.swiper {
    height: 450px !important;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ads-sec-slider .swiper {
    height: 340px !important;
}

/* resume templates page css end */


/* add popup section css start */
.popup-gray-bg {
    background-color: #f0f3f5;
}

.popup-card {
    padding: 15px;
    height: 130px;
}

.no-thanks-btn {
    padding: 6px 24px !important;
    color: #0c87d4 !important;
    border: 1px solid rgba(62, 121, 211, 0.5) !important;
    background: none;
}

.add-section-btn {
    padding: 6px 24px !important;
    background-color: #0c87d4 !important;
}

.w-check {
    width: 22px !important;
    height: 22px !important;
}

.card-section {
    box-sizing: border-box;
    box-shadow: rgb(231 235 238) 0px 3px 8px 0px, rgb(181 186 189) 0px 1px 4px 0px;
    align-items: center;
    padding: 12px 16px 12px 16px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
}

.card-section:hover {
    box-shadow: rgb(217 221 224) 0px 0px 4px 0px;
}

.icon-social {
    background-color: #dbdddf;
    padding: 6px 6px;
    color: #0c87d4;
    align-items: center;
}

.boder-another {
    border: 2px dashed #0c87d4;
    padding: 12px 16px 12px 16px;
    color: #0c87d4;
}

.text-blue {
    color: #0c87d4;
}

.download-btn {
    background-color: #0c87d4 !important;
    padding: 8px 60px !important;
}

.download-btn:hover {
    background-color: #5684ca !important;
    padding: 8px 60px !important;
}

/* add popup section css end */

/* experience card css start */
.experience-card {
    padding: 8px 20px;
    box-sizing: border-box;
    background-color: #edf1f4e3;
    cursor: pointer;
    box-shadow: rgb(231 235 238) 0px 3px 8px 0px, rgb(181 186 189) 0px 1px 4px 0px;
}

.experience-card:hover {
    box-shadow: rgb(217 221 224) 0px 0px 4px 0px;
}

.expr-p {
    color: #6d7275;
}

.example-badge {
    color: white;
    line-height: 16px;
    position: absolute;
    top: 0px;
    right: 29px;
    margin-top: -12px;
    background: #0c87d4;
    border-radius: 3px;
    padding: 4px 8px;
    font-size: 12px;
    cursor: auto;
}

/* .css-14el2xx-placeholder {
    visibility: hidden;
    position: relative;
}

.css-14el2xx-placeholder:after {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    content: "Select";
} */

.written-suggestion {
    transition: background-color 250ms ease-in-out 0ms, color 250ms ease-in-out 0ms, box-shadow 250ms ease-in-out 0ms;
    cursor: pointer;
    padding: 16px;
    color: rgb(90, 94, 97);
    box-shadow: rgb(181 186 189) 0px 1px 4px 0px, rgb(231 235 238) 0px 3px 8px 0px;
    background-color: rgb(255, 255, 255);
    margin-top: 16px;
}

.written-border {
    border-width: 1px 1px 1px 5px;
    border-style: solid;
    border-color: #0c87d4;
}

.written-suggestion:hover {
    box-shadow: rgb(181 186 189 / 75%) 0px 3px 8px 0px;
}

.written-suggestion:hover .title-written {
    color: #0c87d4;
}

.written-suggestion:hover .icon-section {
    background-color: #4f8ae47d !important;
}

.written-suggestion:hover .icon-gray {
    color: #0c87d4 !important;
}

.kEVnji {
    width: 34px;
    height: 34px;
    background: #0c87d43b;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #0c87d4;
}

/* experience card css end */

.tips-modal-footer.modal-footer {
    justify-content: flex-start !important;
}

.tips-icon {
    background-color: #00b786;
    padding: 4px 4px;
}

.text-cap:hover {
    color: #00b786;
}

.contact-tips {
    background-color: rgb(218, 248, 241);
    border-width: 1px 1px 1px 4px;
    border-style: solid;
    border-color: rgb(0, 211, 169);
    padding: 12px 16px !important;
}

.border-experience {
    border: 1px solid #0c87d4;
    border-bottom: none;
}

.border-experience:hover {
    border: 1px solid #0c87d4;
}

.bg-field {
    background-color: rgb(240, 243, 245) !important;
    border-radius: 6px !important;
}

.bg-field:active,
.bg-field:focus {
    background-color: #fff !important;
}

.helper-text {
    font-size: 0.75rem;
}

.form-float .form-floating .form-control:focus+.label-float {
    color: #26a0f4 !important;
}

.form-float .form-floating>.form-control::placeholder {
    color: revert !important;
}

.form-float .form-floating>.form-control:not(:focus)::placeholder {
    color: transparent !important;
}


.line-height-star {
    height: 30px;
}

.accounting .nav-tabs {
    border-bottom: 0px !important;
}

.tab-account .nav-link {
    background-color: none !important;
    border: none !important;
    color: #000;
}

.tab-account .nav-link.active {
    background-color: #f3f3f3 !important;
    border-bottom: 4px solid #2cb02c !important;
    color: #000 !important;
}

.tab-account {
    font-size: 16px;
    font-weight: 700;
}

#uncontrolled-tab-example-tab-account_setting::after {
    content: url(../public/images/arrow-down.png);
    vertical-align: sub;
    margin-left: 4px;
}

#jobseeker-maintabs-tab-accountsetting::after {
    content: url(../public/images/arrow-down.png);
    vertical-align: sub;
    margin-left: 4px;
}

.tab-account-company {
    font-size: 16px;
    font-weight: 700;
}

.nav-border-account .nav-head:link,
.nav-head:visited {
    color: #000;
    padding: .57em;
}

.nav-border-account .nav-head:hover {
    border-bottom: 4px solid #2cb02c !important;
}

.nav-border-account .nav-head:active {
    border-bottom: 4px solid #2cb02c !important;
}

.nav-border-account .nav-head:focus {
    border-bottom: 4px solid #2cb02c !important;
    outline: none;
    color: #0c87d4;
}

.account-arrow::after {
    content: url(../public/images/arrow-down.png);
    vertical-align: sub;
    margin-left: 4px;
}

#employers-btn-dropdown #dropdown-account.btn.btn-primary {
    background-color: #f3f3f3 !important;
    border: none !important;
    color: #000;
}

#employers-btn-dropdown #dropdown-account.btn.btn-primary:active {
    color: #0c87d4;
    border-bottom: 4px solid #2cb02c !important;
}

#employers-btn-dropdown #dropdown-account.btn.btn-primary:hover {
    color: #0c87d4;
    border-bottom: 4px solid #2cb02c !important;
}

.filter-border {
    padding: 5px 16px;
    border: 2px solid #0c87d4;
}

.w-50px {
    width: 50px !important;
}

.btn-no .btn-outline-secondary {
    display: none !important;
}

.icon-phone {
    width: 32px !important;
    height: 38px !important;
}

.icon-whatsapp {
    width: 42px !important;
    height: 42px !important;
}

.mt-25 {
    margin-top: 25px;
}

.bg-pending {
    padding: 20px 14px 20px;
    background: #383c47;
}

.color-pink {
    color: #e15550 !important;
}

.color-silver {
    color: #acb2c4 !important;
}

.color-status {
    color: #217c7c;
}

.history {
    font-size: 18px;
    font-weight: 700 !important;
}

.listing-tab {
    font-size: 13px;
    font-weight: 700 !important;
    border-bottom: 2px solid red !important;
}

.listing-tab button {
    color: #808080 !important;
    text-transform: uppercase;
    padding: 16px;
}

.listing-tab button:hover {
    color: #0c87d4 !important;
}

.width-60 {
    width: 60%;
}

.width- {
    width: 50%;
}



.width-65 {
    width: 65%;
}

.width-15 {
    width: 15%;
}

.width-10 {
    width: 10%;
}

.width-12 {
    width: 12%;
}

.mt-30-minus {
    margin-top: -30px;
}

.text-gray {
    color: #808080;
}

.p-follow {
    padding: 4px 22px !important;
}

.ptb-15 {
    padding: 8px 15px !important;
}

.bg-green {
    background-color: #2cb02c !important;
}

.bg-blue {
    background-color: #0c87d4 !important;
}

.h-148 {
    height: 148px;
}

.h-138 {
    height: 138px;
}

.w-20 {
    width: 20%;
}

.w-18 {
    width: 18%;
}

.w-8 {
    width: 8%;
}

.w-12 {
    width: 12%;
}

.w-13 {
    width: 13%;
}

.institution-logo {
    height: 200px !important;
    width: auto !important;
}

.btn-login {
    padding: 12px 40px;
    background-color: #0c87d4;
}

/*begin first box*/
.first {
    width: 100%;
    margin-top: 60px;
    position: relative;
}

.first span {
    position: absolute;
    display: flex;
    right: 0;
    left: 0;
    align-items: center;
}

.first .bar {
    bottom: -8.5px;
}

.first span:before {
    margin-right: 15px;
}

.first span:after {
    margin-left: 15px;
}

.first span:before,
.first span:after {
    content: ' ';
    height: 3px;
    background: #dadce0;
    width: 50%;
}


.benefit-bar {
    width: 100%;
    margin-top: 38px;
    margin-bottom: 55px;
    position: relative;
}

.benefit-bar span {
    position: absolute;
    display: flex;
    right: 0;
    left: 0;
    align-items: center;
}

.benefit-bar .bar {
    bottom: -8.5px;
}

.benefit-bar span:before {
    margin-right: 15px;
}

.benefit-bar span:after {
    margin-left: 15px;
}

.benefit-bar span:before,
.benefit-bar span:after {
    content: ' ';
    height: 1px;
    background: #dadce0;
    width: 50%;
}


.login-btn {
    font-size: 12px;
    padding: 8px;
    border: 2px solid #dadce0 !important;
    font-weight: 600;
    color: #63686c;
    background-color: #fff;
}

.login-btn:hover {
    background-color: #f1f5f9;
}

/* require confirm style is start */
.form-mail-verify {
    max-width: 550px !important;
    margin: 0 auto;
    box-shadow: 0 0 2px rgb(0 0 0 / 20%);
    background: #fff;
}

.p-mail {
    background-color: #f4f8fb;
    padding: 30px 25px 30px;
}

.h1-title {
    font-size: 48px;
    font-weight: 600;
    margin: 2;
    color: #245fa8 !important;
    margin-top: 10px;
    margin-bottom: 15px;
}

.h3-desc {
    font-size: 27px;
    font-weight: 700;
    margin: 10px auto;
}

.bottom-width {
    border-bottom: #b4b2b2;
    border-bottom-style: solid;
    width: 30% !important;
    margin: 20px auto;
}

.verify-btn {
    background-color: #0186d2 !important;
    padding: 15px 0px !important;
    font-weight: 700 !important;
    font-size: 20px !important;
}

.cursor-no {
    cursor: default !important;
    background: none !important;
}

/* mobile responsive design */
@media only screen and (max-width: 600px) and (min-width: 370px) {
    .font-mob {
        font-size: 12px !important;
    }

    .m-top-mob {
        margin-top: 16px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 340px) {
    .p-right-mobile {
        padding-right: 50px;
    }
}

.w-40-account {
    width: 35px;
    height: 35px;
}

#noanim-tab-example-tab-addjob::before {
    content: url(../public/images/add.png);
    margin-right: 7px;
}

#noanim-tab-example-tab-addseminar::before {
    content: url(../public/images/add.png);
    margin-right: 7px;
}

#noanim-tab-example-tab-addjobfortender::before {
    content: url(../public/images/add.png);
    margin-right: 7px;
}

.foot-color {
    color: #399df6 !important;
}

@media only screen and (max-width: 768px) and (min-width: 358px) {
    .mb-mob-pre {
        margin-bottom: 20px;
    }
}

.add-btn {
    color: #5a5e61;
    font-size: 16px;
    padding: 10px 38px;
    border: none;
    background-color: #D9DDE0;
}

.add-btn:hover {
    background-color: #969b9e;
}

.resume-header {
    width: 100%;
    background-color: rgb(255, 255, 255);
    border-bottom: 2px solid rgb(231, 235, 238);
    height: 80px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
}

.logo-resume {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
    border-right: 2px solid rgb(231, 235, 238);
    padding: 0px 32px;
}

/* resume builder section start css */
.temp-btn {
    height: 36px;
    padding: 3px 16px 4px 12px;
    border-radius: 4px;
    color: rgb(90, 94, 97);
    background-color: #d9dde0;
    border: none;
    fill: #fff;
}

.template-fill {
    fill: rgb(90, 94, 97);
}

.template-fill:hover {
    fill: #fff;
}

.temp-btn:hover {
    background-color: rgb(150, 155, 158);
    color: rgb(255, 255, 255);
    fill: rgb(255, 255, 255);
}

/* resume template one css */
.template * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.preview-template {
    width: 100%;
    height: auto;
}

#template-full {
    background: white;
    border: none;
    transform-origin: 0px 0px;
    min-height: 100vh;
}

.template-main {
    color: #010101;
    font-size: 11px;
    line-height: 1.5;
}

.template-header {
    padding: 30px 50px;
    background: #f0f5fb;
    color: #303578;
    font-size: 30px;
    font-weight: 600;
    line-height: 50px;
}

.template-header .head {
    margin-right: 16px;
}

.template_subtitle {
    font-size: 11.5px;
    margin-bottom: 5px;
    line-height: 1.5em;
}

.template_content {
    padding: 30px 35px 40px;
}

.template-section-title {
    font-size: 12px;
    color: #303578;
    width: 150px;
    border-bottom: 1px solid rgba(48, 53, 120, .2);
    padding-bottom: 16px;
    font-weight: 600;
    margin-right: 16px;
}

.template-header-info {
    width: calc(100% - 83px - 40px - 4px);
    /* padding-right: 16px; */
    border-bottom: 1px solid rgba(48, 53, 120, .2);
    padding-bottom: 16px;
    color: #010101;
    font-size: 11px;
    line-height: 1.5;
}

.sec-subtitle {
    font-weight: 600;
    position: relative;
    color: #303578;
}

.sec-value,
.sec-value p {
    color: rgb(1 1 1 / 81%);
    font-size: 11px;
    line-height: 1.5;
    word-break: break-word;
}

.mb-16 {
    margin-bottom: 16px;
}

.title-objective {
    font-size: 12px;
    color: #303578;
}

.color-blue-head {
    color: #77a4db;
}

.w-150 {
    width: 150px;
}

.w-125 {
    width: 125px;
}

.m-right {
    margin-right: 16px;
}

.header-info {
    /* width: calc(100% - 83px - 40px - 4px); */
    padding-bottom: 16px;
    color: #010101;
    font-size: 11px;
    line-height: 1.5;
}

.border-bottoms {
    border-bottom: 1px solid rgba(48, 53, 120, .2);
}

.quote-img:before {
    content: url(../public/images/quotes.svg);
    position: absolute;
    height: 19px;
    width: 24px;
    left: -41px;
    top: 2px;
}

.sidebar-template {
    background: #17a095;
    padding: 22px 5px 0 20px;
    color: #fff;
    width: 210px;
    vertical-align: top;
    font-size: 11.5px;
    /* height: 100vh; */
}

.sidebar-template-newyork {
    background: #3176E0;
    padding: 22px 14px 0 14px;
    color: #fff;
    width: 210px;
    vertical-align: top;
    font-size: 11.5px;
}

.mb-7 {
    margin-bottom: 7px;
}

.template-classic-header {
    background-color: #eee;
    padding: 18px 18px 20px 20px;
    margin-bottom: 24px;
}

.head-two {
    font-size: 18px;
    font-weight: 600;
}

.padding-side {
    padding: 0px 22px;
}

.dot-icon:before {
    content: "";
    width: 6px;
    height: 6px;
    background-color: #303030;
    position: absolute;
    top: 10px;
    left: 0px;
}

.border-circle {
    border-top: 2px solid rgba(255, 255, 255, .3);
    margin-top: 18px;
    margin-bottom: 15px;
    width: 150px;
}

.border-circle:before {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    top: -5px;
    left: 0px;
    padding-right: 19px;
    font-size: 14px;
    line-height: 8px;
    color: rgba(255, 255, 255, .8);
    padding-right: 15px;
}

.border-gray {
    border-bottom: 2px solid rgba(255, 255, 255, .3);
    padding-bottom: 10px;
}

.border-vertical:before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #303030;
    position: absolute;
    top: 0px;
    left: -22px;
    border-radius: 50%;
}

.quote3-img:before {
    content: url(../public/images/quotes.svg);
    position: absolute;
    height: 10px;
    width: 10px;
    left: -17px;
    top: 2px;
}

.template_content-4 {
    padding: 10px 35px 40px;
}

.contact-bg {
    background-color: #eee;
    padding: 18px 35px 24px 35px;
}

/* resume design 5 */
.template-header-5 {
    padding: 50px 40px 0;
}

.sidebar-template-5 {
    padding: 22px 5px 0 20px;
    width: 190px;
    vertical-align: top;
    font-size: 11.5px;
}

.border-black {
    border-bottom: 2px solid rgb(194 190 190 / 57%);
    padding-bottom: 10px;
}

.border-left-5 {
    border-left: 2px solid #d0021b;
    padding-left: 7px;
}

.doc-preview-finish {
    pointer-events: auto;
    margin: 40px 0px 70px;
    min-height: calc((((((((100vh - 80px) - 8px) - 36px) - 40px) - 20px) - 42px) - 16px) - 150px);
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
    .doc-preview-finish {
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media only screen and (max-width: 420px) and (min-width: 315px) {
    .zoom-mobile-resume {
        zoom: 0.6;
    }
}

.scrollbar-col-6 {
    height: calc((100vh - 80px) - 8px);
    background: rgb(248, 249, 250);
    overflow: hidden auto;
}

.scrollbar-col-nobg {
    height: calc((100vh - 80px) - 8px);
    overflow: hidden auto;
}

.pl-pr-80 {
    padding-left: 48px;
    padding-right: 48px;
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
    .pl-pr-80 {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.bg-trans {
    background-color: transparent !important;
    width: 168px !important;
}

.template-slider-bottom {
    background-color: rgb(168 168 168);
    height: 310px !important;
}

.border-bottom-slider {
    border-bottom: 2px solid rgba(61, 64, 66, 0.33);
}

.my-bottom-slider {
    border-bottom: 3px solid rgba(13, 82, 128, 0.918);
}

.border-bottom-slider .h5 {
    text-align: center !important;
    color: #fff;
    width: 100%;
}

.template-sliders .swiper {
    height: 200px !important;
}

/* .template-sliders .swiper-slide{
    width: 18px !important;
} */

/* Scrollbar Styling */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: #e7ebee;
    -webkit-border-radius: 10px;
    border-radius: 16px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #b5babd;
}

::-webkit-scrollbar-thumb:hover {
    background: #6d6d6dc7;
}

.resume-inner-sec-width {
    max-width: 550px;
    align-items: flex-start;
    width: 100%;
    margin: 0px auto;
    padding-bottom: 0px;
}

.next-btn {
    background-color: rgb(255, 148, 148);
    padding: 11px 30px;
    font-size: 14px;
}

.text-level {
    color: rgb(150, 155, 158);
}

.wixLH {
    position: fixed;
    z-index: 999;
    transition: all 0.5s ease-in-out 0s;
    bottom: 0px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    left: 0px;
    width: 60%;
}

.lgfxib {
    width: calc(100% - 16px);
    max-width: calc(620px);
    box-sizing: border-box;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 24px 15px;
    margin: 0px 8px;
    border-top: 1px solid rgb(231, 235, 238);
    box-shadow: rgb(0 0 0 / 50%) 0px -10px 25px -26px;
    background-color: rgb(255, 255, 255);
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    opacity: 0.9;
}

/* editor css in plugin override */
.rdw-editor-toolbar {
    background: rgb(240, 243, 245) !important;
    border-radius: 4px !important;
    margin-bottom: 0px !important;
    border-bottom: 3px solid #d2d3d4 !important;
}

.editor-height {
    height: 170px !important;
    background: rgb(240, 243, 245) !important;
    border-radius: 4px;
}

.obj-list-margin-left ul {
    margin-left: 22px;
}

.obj-list-margin-left ol {
    margin-left: 28px;
}

.editor-list-margin-left ul {
    margin-left: 20px;
}

.editor-list-margin-left ol {
    margin-left: 18px;
}

.edit-comp-bg {
    background: #fff !important;
}

.public-DraftStyleDefault-block {
    margin: 0px 12px !important;
    padding-top: 5px !important;
}

.save-btn-resume {
    background-color: #0c87d4 !important;
    padding: 10px 15px !important;
    font-size: 16px;
    line-height: 14px;
    border-radius: 100px;
    letter-spacing: 1.25px;
    border: none;
}

.styles-modal-width-cs {
    max-width: 650px;
    margin: auto;
}

.modal-101w-styles .modal-header {
    border-bottom: none !important;
}

.previous-btn {
    background-color: #214053 !important;
    padding: 10px 15px !important;
    font-size: 16px;
    line-height: 14px;
    border-radius: 100px;
    letter-spacing: 1.25px;
    border: none;
}

.next-btn-resume {
    background-color: #0c87d4 !important;
    padding: 10px 15px !important;
    font-size: 16px;
    line-height: 14px;
    border-radius: 100px;
    letter-spacing: 1.25px;
    border: none;
}

@media only screen and (max-width: 400px) and (min-width: 320px) {
    .previous-btn {
        font-size: 14px;
    }

    .next-btn-resume {
        font-size: 14px;
    }
}

@media only screen and (max-width: 375px) and (min-width: 315px) {
    .previous-btn {
        padding: 8px 8px !important;
        font-size: 12px;
    }

    .next-btn-resume {
        padding: 8px 8px !important;
        font-size: 12px;
    }
}

.jss1766 {
    color: #fff;
    width: 24px;
    height: 24px;
    padding: 4px;
    background-color: #0090f2;
    border-radius: 50%;
}

.jss1766-text {
    color: #0090f2;
}

.jss1800 {
    color: #fff;
    width: 27px;
    height: 27px;
    padding: 5px;
    background-color: #dc3545;
    border-radius: 50%;
}

.bottom-btn-sticky {
    position: sticky;
    z-index: 999;
    transition: all 0.5s ease-in-out 0s;
    bottom: 0;
    left: 90px;
    margin-top: 75px;
}

.next-previous-btns {
    box-sizing: border-box;
    padding: 24px 5px;
    border-top: 1px solid rgb(231, 235, 238);
    box-shadow: rgb(0 0 0 / 50%) 0px -10px 25px -26px;
    background-color: rgb(255, 255, 255);
    opacity: 0.9;
}

.objective-finish {
    background-color: #fd2929 !important;
    padding: 10px 15px !important;
    font-size: 16px;
    line-height: 14px;
    border-radius: 100px;
    letter-spacing: 1.25px;
    border: none;
}

.switch-photo-label input,
label {
    cursor: pointer;
    font-weight: 400;
    color: #1e2021;
    font-size: 15px;
}

.template-toronto-header {
    padding: 15px 26px 15px;
}

.border-top-dashed {
    border-top: 1px dashed rgba(48, 48, 48, .5);
}

.tornto-img {
    width: 75px;
    height: auto;
}

.title-bg-black {
    background-color: #303030;
    color: #fff;
    padding: 5px 8px;
}

.template-auckland-header {
    padding: 15px 35px 0px;
}

.border-left-haiphong {
    border-left: 1px solid #fff;
    padding-left: 6px;
}

.template-haiphong-header {
    padding: 20px 20px;
    background-color: #D0021B;
}

.border-vertical-haiphoung:before {
    content: "";
    width: 8px;
    height: 8px;
    background-color: #D0021B;
    position: absolute;
    top: 0px;
    left: -20px;
    border-radius: 50%;
}

.border-vertical-y {
    border-left: 1px solid #30357833;
}

.title-bg-red {
    background-color: #D0021B;
}

.text-red-haiphoung {
    color: #D0021B;
}

fieldset {
    border: 1px solid #000;
}

.sec-around-border {
    border: 1px solid #d0d0d0;
    position: relative;
}

.sec-around-border-title {
    margin-left: 16px;
    background-color: #fff;
    color: #9a9a9a;
    position: absolute;
    left: -5px;
    top: -10px;
    font-size: 12.5px;
}

.p-margin-sec-inner {
    margin-top: 20px;
    padding: 0 16px;
    margin-bottom: 20px;
}

.address-amsterdam::before {
    margin: 0 4px 0 0;
    content: "|";
}

.sidebar-template-amsterdam {
    background: #BFA9A9;
    padding: 64px 10px 0 20px;
    color: #423b3b;
    vertical-align: top;
    font-size: 11.5px;
    min-height: 100vh;
}

.template-header-amsterdam {
    padding: 25px 22px 24px 22px;
}

.padding-side-amsterdam {
    padding: 0px 12px;
}

.border-left-amsterdam::before {
    content: "";
    position: absolute;
    height: 32px;
    width: 3px;
    margin-left: -15px;
    border-radius: 2px;
    background: #bfa9a9;
}

.tip-line-height {
    line-height: 16px;
}

.tips-dot::marker {
    color: rgb(38, 160, 244);
    font-size: 20px;
}

.yqEhG {
    width: 300px;
}

.profile-img01 {
    color: rgb(30, 32, 33);
    padding: 4px;
    background-color: #babdbf;
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
    .mob-flex-col {
        flex-direction: column;
        align-items: start;
    }
}

/* back to top button in css */
.back-to-top {
    position: fixed;
    bottom: 85px;
    right: 25px;
    background: #0C87D4;
    padding: 8px;
    color: white;
    border-radius: 50%;
    border: none;
    box-shadow: 0 5px 10px #ccc;
    z-index: 9999;
}

.back-to-top:hover {
    background: #20a3f6;
}

.template-slider-bottom.offcanvas.offcanvas-bottom.show {
    z-index: 9999;
}

.simple-img {
    width: 80px;
    height: auto;
}

.rounded-img {
    border-radius: 80px;
    height: 75px;
    overflow: hidden;
}

.react-confirm-alert-overlay {
    z-index: 9999 !important;
}

.date-zindex .react-datepicker-popper {
    z-index: 9999 !important;
}

.h-100vh {
    height: 100vh;
}

.h-50vh {
    height: 50vh;
}

.color1 {
    height: 20px;
    width: 20px;
    border-radius: 50px;
    display: inline-flex;
    -webkit-box-pack: center;
    -webkit-box-align: center;
}

.color-list {
    width: 28px;
    margin: auto
}

.color-dropdowns .dropdown-menu {
    min-width: 90px !important;

}

.color-dropdowns .dropdown-menu.show {
    display: flex !important;
    flex-wrap: wrap !important;
    border: none;
    background-color: #D9DDE0;
}

.color-dropdowns .dropdown-menu .dropdown-item {
    width: 50% !important;
    padding: 0;
}

.color-dropdowns .dropdown-toggle::after {
    margin-top: 10px;
    font-size: 25px;
}

.resume-preview-options {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    margin-left: auto;
    gap: 35px;
}

.color-dropdowns .bg-color-dropdown {
    padding: 4px;
    background-color: #D9DDE0;
    border-color: #D9DDE0;
}

.color-dropdowns .bg-color-dropdown:hover {
    background-color: #D9DDE0;
    border-color: #D9DDE0;
}

.color-dropdowns .dropdown-menu .dropdown-item:active {
    background-color: #D9DDE0;
    border-color: #D9DDE0;
}

.m-font-dropdown {
    background-color: #D9DDE0;
    border-color: #D9DDE0;
}

.m-font-dropdown:focus {
    background-color: #D9DDE0;
    border-color: #D9DDE0;
}

.m-font-dropdown:hover,
.m-font-dropdown:active {
    background-color: #969b9e;
    border-color: #969b9e;
}

.m-font-dropdown .m-font-options {
    padding: 5px 8px;
}

.input-bg-plus {
    background-color: #D9DDE0;
    border-color: #D9DDE0;
}

.input-bg-plus .no-bg:active {
    background: none;
}

.toggle-btn-size .small {
    background-color: #D9DDE0;
    border-color: #D9DDE0;
    color: rgb(12, 12, 12);
}

.toggle-btn-size .medium {
    background-color: #D9DDE0;
    border-color: #D9DDE0;
    color: rgb(12, 12, 12);
    margin-left: 0.1em !important;
    margin-right: 0.2em;
}

.toggle-btn-size .large {
    background-color: #D9DDE0;
    border-color: #D9DDE0;
    color: rgb(12, 12, 12);
}

.toggle-btn-size .btn-check:checked+.btn {
    background-color: #969b9e;
    border-color: #969b9e;
}

.amsterdam-img {
    width: 150px;
    height: auto;
}

.word-break {
    word-break: break-word;
}

.image-active-icon {
    position: absolute;
    background-color: rgb(255, 255, 255);
    border-radius: 100%;
    top: 50%;
    left: 50%;
    color: rgb(38, 160, 244);
    transform: translate(-50%, -50%);
}

.template-container-resume.active {
    outline: 4px solid rgb(38, 160, 244);
    zoom: 0.95;
}

.mentovideo-subtitle {
    color: #17a095;
    font-size: 17px;
    font-size: 600;
}

.color-black {
    color: rgb(1 1 1 / 81%);
}

.border-1px {
    border: 1px solid #d0d0d0;
}

.font-w700 {
    font-weight: 700;
}

.widht-80px {
    width: 125px;
}

.full-screen-modal-design {
    width: 850px;
    padding: 0px;
    background: white;
    border: none;
    box-shadow: rgb(217 221 224) 0px 0px 34px 0px;
}

.progress-green .progress-bar {
    background: #2cb02c !important;
}

.para-white p {
    color: #fff;
}

.haiphoung-img-w {
    width: 75px;
    min-width: 75px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 450px) and (min-width: 340px) {
    .full-screen-modal-design {
        width: 410px;
    }
}

@media only screen and (max-width: 600px) and (min-width: 451px) {
    .full-screen-modal-design {
        width: 480px;
    }
}

@media only screen and (max-width: 750px) and (min-width: 601px) {
    .full-screen-modal-design {
        width: 580px;
    }
}

@media only screen and (max-width: 870px) and (min-width: 751px) {
    .full-screen-modal-design {
        width: 650px;
    }
}

@media only screen and (max-width: 840px) and (min-width: 350px) {
    .mobile-topbar-option {
        flex-direction: column;
        gap: 10px;
    }
}

.gap-22 {
    gap: 25px;
}

.pdf-col-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
    padding-right: 1.5rem;
}

.pdf-col-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

@media only screen and (max-width: 360px) {
    .resume-preview-options {
        gap: 4px;
    }
}

@media only screen and (max-width: 667px) and (min-width: 375px) {
    .resume-preview-options {
        gap: 20px;
    }
}

.skill-match-bold {
    font-weight: 700;
    font-size: 22px;
    color: #0c87d4;
    text-align: center !important;
}

.service-tagifys {
    width: 100%;
}

.place-holder-box {
    width: 150px;
    height: 150px;
    background: url('../public/images/dummy-img-company.png');
    background-size: cover;
}

.checkbok-company-color {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #0c87d4f2;
}

.footer-bg {
    background-color: #1e1c34;
    color: #f5f3ff;
}

.menu-ul-list a {
    text-decoration: none;
    font-weight: 600;
    color: #f5f3ff;
}

.social-links-padd a {
    padding: 10px 14px;
    background-color: #3a3555;
    color: #f5f3ff;
    border-radius: 6px;
}

.py-40-footer {
    padding-top: 40px;
    padding-bottom: 30px;
}

@media only screen and (max-width: 768px) and (min-width: 320px) {
    .w-mob-desc-footer {
        width: 100% !important;
    }
}

.border-blue-resume .modal-content {
    border: 3px solid #6219b7;
    background: #f6f2f9;
}

.bg-dark-blue {
    background-color: #6219b7;
}

.close-padd {
    padding: 5px;
    background-color: #b0281e;
    color: #fff;
    border-radius: 6px;
    font-weight: 700;
}

.custom-label-files {
    background-color: #ffffff;
    border: 1px solid #6219b7;
    color: #6219b7;
    font-weight: 700;
    border-right: 3px solid #6219b7;
    align-items: center;
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    display: flex;
    font-size: 1rem;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    text-align: center;
    white-space: nowrap;
}

.custom-label2-files {
    background-color: #dde0e3;
    border: 1px solid #dde0e3;
    color: #000000;
    border-right: 1px solid #dde0e3;
    align-items: center;
    display: flex;
    font-size: 1rem;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    text-align: center;
    white-space: nowrap;
}


.custom-file-button {
    margin-left: -2px !important;
}

.custom-file-button:hover label {
    background-color: #dde0e3;
    cursor: pointer;
}

.no-img-box {
    background-image: url('../public/images/dummy-img-company.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 150px;
}

.no-img-title {
    font-weight: 700;
    font-size: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}


.ht-labels {
    padding-right: 0px;
    background-color: #DCD9D9;
}

.ht-post-code .form-control:focus {
    outline: none !important;
    padding-left: 4px !important;
    border: none;
}

.text-job-id {
    color: rgb(12, 135, 212);
    font-weight: 600;
}

.seminar-img {
    object-fit: cover;
    height: 250px;
}

.account-sec-padd {
    background-color: #f8f9fb;
    padding-top: 40px;
    padding-bottom: 30px;
}

.account-card-border {
    border-top: 3px solid #0C87D4 !important;
    height: 560px;
    border: 1px solid #0C87D4;
}

.list-group-border-none .list-group-item {
    border: none;
    padding-top: 4px;
    padding-bottom: 4px;
}

.account-badge-plan {
    color: white;
    position: absolute;
    top: 0px;
    right: 25px;
    margin-top: -18px;
    background: #0C87D4;
    border-radius: 50px;
    padding: 8px 10px;
    font-size: 12px;
    cursor: auto;
    z-index: 99;
}

.bor-bottom-plan-account {
    border-bottom: 2px solid #cfcfcfa3;
}

.plan-icon-color {
    color: #0C87D4;
}

.template-plan-icon {
    fill: #0C87D4;
}

.plan-label-radio {
    font-size: 1.5rem;
    font-weight: 700;
}

.bg-back {
    background-color: #dc3545 !important;
}




.left-sidebar-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 72px;
    /* height: 100%; */
    /* background-color: #eff1f4; */
    color: #fff;
    /* z-index: 99999; */
    padding: 5px 5px;
    /* box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%); */
    transition: all 900ms linear 0.12s;
}

@media only screen and (max-width: 600px) and (min-width: 340px) {
    .left-sidebar-menu {
        width: 50px;
    }

    .left-icon-employee {
        height: 20px !important;
        width: 20px !important;
    }

    .p-list-leftside {
        padding: 8px !important;
    }

    .left-arrow-img {
        width: 25px !important;
        height: 25px !important;
    }

    .toggle-sidebar {
        padding: 8px !important;
    }
}

@media only screen and (max-width: 992px) and (min-width: 601px) {
    .toggle-sidebar {
        padding: 6px !important;
    }
}

.toggle-sidebar {
    padding: 18px;
    cursor: pointer;
}

.p-list-leftside {
    padding: 12px;
}

.left-icon-employee {
    height: 33px;
    width: 33px;
}

.p-navtitle {
    padding: 0px 15px;
    font-weight: 600;
    font-size: 18px;
}

.p-list-leftside:active,
.p-list-leftside:hover {
    box-shadow: -7px 0px 0px 0px rgb(12, 135, 212);
}

.leftsidebar-employee-w {
    width: 220px;
    transition: all 900ms linear 0.12s;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #eff1f4;
    color: #fff;
    z-index: 99999;
    padding: 12px 7px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
}

.letter-space-3 {
    letter-spacing: 3px;
}

.invoice-sec-logo {
    width: auto;
    height: 60px;
}

.invoice-billing-bg {
    background-color: #f5f7f8;
    padding: 11px 3px;
    margin: 0px;
}

.invoice-tr-bg {
    background-color: #f5f7f8;
}

.print-invoice-btn {
    background: #212529 !important;
}

.left-arrow-img {
    width: 38px;
    height: 38px;
}

.print-invoice-btn {
    background: #000 !important;
}

.fw-dropdown-right a {
    font-weight: 500;
}

.skillAssessmentBadge {
    background: #DDDDDD;
    padding: 10px 10px 10px 10px;
}

.copyright-text {
    font-size: 14px;
    color: #B9ADAD;
    font-weight: 500;
}

.modal-8inch-wprint {
    max-width: 8.5in !important;
    font-family: Arial, Helvetica, sans-serif;
}

.modal-6inch-wprint {
    max-width: 6.5in !important;

}

.gap-18 {
    gap: 18rem;
}

/* privacy all pages css */
.fontsize-privacy p {
    font-size: 1rem;
    font-family: 'Roboto', sans-serif !important;
    margin: 0 0 1.5em;
    color: #03153a;
    font-weight: 400;
}

.title-privacy {
    font-size: 2.875rem;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 800 !important;
    color: #03153a;
}

.subtitle-privacy {
    font-size: 2rem !important;
    font-family: 'Roboto', sans-serif !important;
    color: #03153a;
    margin: 0 0 1.2rem !important;
}

.seminar-bg-form {
    background-color: #f3f3fe;
    border-top: 8px solid #b5bcc5 !important;
}

.navbar-account-bg {
    background-color: #0d87d4;
    color: #e4e9ed;
    font-weight: 500;
}

.navbar-account-bg a {
    color: #e4e9ed;
}

.account-nav:hover {
    font-weight: 700;
    color: #e4e9ed;
}

.navbar-account-bg:hover {
    background-color: #e77e00;
}

.upgrade-account-sec {
    background-image: url('../public/images/upgrade-account/account-posting-bg.jpg');
    padding-top: 40px;
    padding-bottom: 30px;
    background-size: cover;
    background-repeat: no-repeat;
}

.upgrade-account-card {
    border-top: 7px solid #0C87D4 !important;
    height: 425px;
}

.seminar-employee-w {
    width: 500px;
    height: 260px;
}

.call-tender-sec {
    background-image: url('../public/images/upgrade-account/call-tender-bg.jpg');
    padding-top: 40px;
    padding-bottom: 30px;
    background-size: cover;
    background-repeat: no-repeat;
}

.special-offer-bg {
    background-color: #dddddd;
}

.offer-card-bg {
    background: linear-gradient(316deg, rgb(250 250 250 / 66%) 0%, rgba(255, 255, 255, 1) 50%, rgb(237 237 237 / 1) 45%);
}

.job-plan-sec {
    background-color: #dddddd;
    padding-top: 30px;
    padding-bottom: 30px;
    background-size: cover;
    background-repeat: no-repeat;
}


.gap-8 {
    gap: 8rem;
}

.resume-bg-db {
    background-image: url('../public/images/upgrade-account/resume-database.png');
    padding-top: 40px;
    padding-bottom: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 265px;
}

.btn-try-now {
    padding: 8px 26px;
    border: 3px solid #fff;
    background-color: #e87f00;
    border-radius: 50px;
    color: #fff;
}

.btn-try-now:hover {
    background-color: #fff;
    color: #e87f00;
}

.text-skyblue {
    color: #12a9ef;
}

.w-35px {
    width: 35px;
}

.format-tip-bg {
    background-color: #f7f7f7;
}

.tip-badge {
    background-color: #000000;
    color: #fff;
    padding: 0px 16px;
    font-size: 26px;
}

.tip-body-bg {
    background-color: #fcfcfc;
    padding: 35px;
    border-bottom: 4px solid #0398f4;
}

.format-3-bg {
    background-color: #f2f9ff;
    color: #0398f4;
}

.format-img {
    width: 200px;
    height: 256px;
}

.mt-60 {
    margin-top: 60px;
}

.badge-ideal {
    padding: 2px 8px;
    background-color: #c6e8c7;
}

.check-green {
    color: #67a36f;
    margin-bottom: 3px;
}

.btn-account-color {
    background-color: #9fe870;
    border-radius: 50px;
}

.btn-account-color:hover {
    background-color: #76f525;
    border-radius: 50px;
}

.btn-account-color:active {
    background-color: #76f525 !important;
    border-radius: 50px;
    border: 0px !important;
}

.btn-account-color:focus {
    outline: 0 !important;
    border: 0px !important;
}

.btn-account-color:visited {
    outline: 0 !important;
    border: 0px !important;
}

.inside-bg-account {
    background-color: #edf0ec;
}

.act-detail-menu-link {
    padding: 12px 20px;
}

.act-detail-menu-link:hover {
    box-shadow: -7px 0px 0px 0px rgb(12, 135, 212);
    background-color: #9fe870;
}

.act-detail-menu-link-active {
    box-shadow: -7px 0px 0px 0px rgb(12, 135, 212);
    background-color: #9fe870;
}

.act-detail-menu-link:hover .text-black-c01 {
    color: #000000 !important;
    font-weight: 700 !important;
}

.date-small-size input {
    padding: 0.375rem 0.75rem;
    font-size: 12px;
    line-height: 6px;
    border-radius: 0.305rem !important;
}

.h-28 {
    height: 28px;
}

.h-20 {
    height: 20px;
}

.h-22 {
    height: 20px;
}

.toggle-btn-mob-j01 {
    height: 50px;
    width: 52px;
}

/* responsive class in mobile */
@media only screen and (max-width: 374px) and (min-width: 260px) {
    .call-tender-sec {
        padding-bottom: 160px !important;
    }

    .toggle-btn-mob-j01 {
        height: 38px;
        width: 38px;
        padding: 0px;
    }

    .flexcolumnsm {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .btn-interest {
        padding: 10px !important;
        font-size: 13px;
    }

    .button-default {
        padding: 8px !important;
        font-size: 13px !important;
    }

    .mb-mob-j03 {
        margin-bottom: 18px;
    }

    .flexcolumn-mob-start-j02 {
        display: flex;
        flex-direction: column;
        align-items: start !important;
    }

    .mt-10px-j02 {
        margin-top: 10px;
    }

    .mt-20-j02 {
        margin-top: 20%;

    }

    .pagination-container {
        display: flex !important;
        list-style-type: none;
        padding-left: 1rem;
    }

    .pagination-btn {
        margin: 4px 0px;
    }

    .pagination-diable {
        margin: 4px 0px;
    }

    .slider-div {
        padding: 40px 8px 20px !important;
    }

    .carousel-caption {
        width: 100% !important;
    }

    .slider-div-h1 {
        font-size: 28px;
    }

    .mob-h2 {
        font-size: 18px !important;
    }

    .resume-btn-smallj04 {
        height: 55px !important;
    }

    .card-month-mob-930px {
        height: 930px !important;
    }

    .format-img {
        width: 170px;
        height: 280px;
    }

    .p-btn {
        padding: 8px 16px !important;
        font-size: 14px;
    }

    .footer-logo {
        width: auto;
        height: 48px;
    }

    h2 {
        font-size: 20px !important;
    }

    h3 {
        font-size: 18px !important;
    }

    h4 {
        font-size: 17px !important;
    }

    h5 {
        font-size: 16px !important;
    }

    h6 {
        font-size: 15px !important;
    }

    .fs-18 {
        font-size: 14px;
    }

    .fs-16 {
        font-size: 13px;
    }

    .tip-badge {
        font-size: 18px;
    }

    .fs-21 {
        font-size: 17px;
    }

    .title-privacy {
        font-size: 1.875rem;
    }

    .subtitle-privacy {
        font-size: 1.210rem !important;
    }

    .pagination-btn {
        padding: 3px 6px !important;
        font-size: 11px !important;
    }

    .pagination-diable {
        padding: 2px 10px !important;
        font-size: 11px !important;
    }

    .btn-register {
        width: 220px;
    }

    .resume-example-img {
        width: auto !important;
        height: 200px !important;
        display: block;
    }

    .px-annonce {
        padding-left: 2rem;
        padding-right: 2rem;
    }


}

@media only screen and (max-width: 450px) and (min-width: 376px) {
    .pagination-container {
        display: flex !important;
        list-style-type: none;
        padding-left: 1rem;
    }

    .pagination-btn {
        padding: 6px 12px !important;
        font-size: 14px !important;
    }

    .pagination-diable {
        padding: 6px 12px !important;
        font-size: 14px !important;
    }

    .px-annonce {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media only screen and (max-width: 530px) and (min-width: 375px) {
    .flexcolumn-mob-start-j02 {
        display: flex;
        flex-direction: column;
        align-items: start !important;
    }

    .mt-10px-j02 {
        margin-top: 10px;
    }

    .resume-btn-smallj04 {
        height: 62px !important;
    }

    .footer-logo {
        width: auto;
        height: 50px;
    }

    .resume-example-img {
        width: auto !important;
        height: 270px !important;
        display: block;
    }

    .px-annonce {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

@media only screen and (max-width: 766px) and (min-width: 531px) {
    .resume-example-img {
        width: auto !important;
        height: 300px !important;
        display: block;
    }

    .px-annonce {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

.feedback-web-checkbox .feedback-input {
    background-color: #ebeef3;
    width: 25px;
    height: 25px;
    border-radius: 0px;
}

.feedback-web-checkbox .feedback-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.gray-form-feedback {
    background-color: #f3f3f3;
}

@media only screen and (max-width: 1199px) and (min-width: 767px) {
    .flexcolumn-mob-start-j02 {
        display: flex;
        flex-direction: column;
        align-items: start !important;
    }

    .upgrade-account-card {
        height: 680px;
    }

    .resume-example-img {
        width: auto !important;
        height: 500px !important;
        display: block;
    }

    .px-annonce {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.resume-btn-smallj04 {
    width: auto;
    height: 70px;
}

@media only screen and (max-width: 510px) and (min-width: 260px) {
    .mob-menu-link-none {
        display: none !important;
    }

}

@media only screen and (min-width: 680px) {

    .contact-link-show {
        display: none !important;
    }




}



.resume-example-img {
    width: auto;
    height: 570px;
    display: block;
}

.fs-large {
    font-size: large;
}

.fs-medium {
    font-size: medium;
}

.fs-small {
    font-size: small;
}

.default-btn-none {
    background: none !important;
    border-style: none !important;
}

@media only screen and (max-width: 650px) and (min-width: 260px) {
    .desktop-social-links-register {
        display: none !important;
    }
}

@media only screen and (max-width: 2150px) and (min-width: 651px) {
    .mob-social-link-register {
        display: none !important;
    }
}

/* admin layout css */
.admin-login-sec-j45 {
    max-width: 450px !important;
    margin: 15% auto auto;
    box-shadow: 0 0 2px rgb(0 0 0 / 28%);
    background: rgb(255 255 255 / 92%);
}

.admin-login-sec-bg {
    background: url('../public/images/admin/admin-login-bg.jpg') center no-repeat;
    background-size: cover;
    height: 100vh;
}

.navbar-admin-bg {
    box-shadow: 0 0 8px hsl(0deg 0% 0% / 20%);
    background-color: #ffffff;
}

.navbar-admin-bg a {
    color: #000 !important;
    font-weight: 500;
}

.navbar-admin-bg a:hover {
    color: #0c87d4 !important;
    font-weight: 500;
}

.custom-shadow {
    box-shadow: 0 0 20px hsl(0deg 0% 0% / 12%);
}

.h-100px {
    height: 100px;
}

.h-150px {
    height: 150px;
}

@media only screen and (max-width: 530px) and (min-width: 260px) {
    .desktop-copyright {
        display: none !important;
    }
}

@media only screen and (max-width: 2550px) and (min-width: 531px) {
    .mobile-copyright {
        display: none !important;
    }
}

.pt-32px {
    padding-top: 32px;
}

.preview-link {}

.preview-link:hover {

    color: #000000 !important;
    font-weight: 700 !important;

}

.preview-sublink {}

.preview-sublink:hover {
    color: #0e76b6 !important;
    font-weight: 700 !important;

}

.my-swal-container {}

.my-swal-popup {
    width: 350px;
    height: 180px;
}

.my-swal-confirm-button {
    /* width: 100px;
    height: 100px; */
    background-color: #075E54 !important;
    margin-top: 0px;
    margin-bottom: 0px;

    /* additional styling for the confirm button */
}

.my-swal-title {
    font-size: 18px;
}

.my-swal-input {
    font-size: 18px;

    height: 30px;
}

.my-swal-placeholder {
    font-size: 16px;
}

.card-untitled2 {
    box-sizing: border-box;
    min-height: 40px;
    max-height: 54px;
    background-color: rgb(255, 255, 255);
    padding: 16px 16px;
    border-top: 2px dashed #c3c4c5;
}



.customRedDropDownTitle {
    color: red !important;

}

.customRedDropDownTitle:hover {
    color: #fff !important;
}

.customRedDropDownIcon {
    color: red;
}

.customRedDropDownIcon:hover {
    color: #fff !important;

}

.customDropdownNoArrow>.dropdown-toggle::after {
    display: none;
}

.resume-zoom {
    transform: scale(0.83);
    transform-origin: top left;
    width: 128% !important;
    max-width: 120% !important;
    /* zoom: 0.4; */
}



.cookieBlock {
    margin-right: 25%;
    margin-left: 30%;
}

.button-container {
    display: flex;
    flex-direction: row;
    gap: 100px;

}

.fw-10 {
    font-size: 10px;
}

.plan-btn-box {
    position: absolute;
    width: 100%;
    left: 0;
    justify-content: center;
    display: flex;
    bottom: 0px;
}

.h-240px {
    height: 240px;
}

.h-250px {
    height: 250px;
}

.last-payment-text {
    /* position: absolute; */
    width: 100%;
    justify-content: start;
    display: flex;
    bottom: 18px;
}

#payment-method-drops .dropdown-item.active,
.dropdown-item:active {
    color: #000000;
    text-decoration: none;
}

.order-summary-bg {
    background: #f5f7f8;
}

.button-danger {
    background-color: #dc3545;
    border-color: #dc3545;
}

/* css add by rashid */
.text-green {
    color: #2cb02c;
}

.announce-example-img {
    width: 100%;
    height: 100%;
    display: block;
}

@media only screen and (max-width: 2600px) and (min-width: 1200px) {
    .px-annonce {
        padding-left: 8rem;
        padding-right: 8rem;
    }
}